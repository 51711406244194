<template>
<div class="words-to-use-wrap">
  <div class="title"><slot></slot></div>
  <div class="words-to-use-block">
    <template v-if="wordsToUse?.length> 0">
      <span v-html="highlightUsedWords()"></span>
    </template>
    <template v-else>
      <div class="placeholder-text">
        Add words for keywords from the section below to start
      </div>
    </template>
  </div>
</div>
</template>

<script>
export default {
  name: "WordsToUse",
  props: {
    wordsToUse: {
      type: Object,
    },
    usedWordsData: {
      type: Object,
    },
    classes: {
      type: String,
    },
    mode: {
      type: String,
    },
  },
  methods: {
    highlightUsedWords() {
      if (this.usedWordsData?.length > 0) {
        const regex = `(?<words>([^\\p{L}\\p{N}]|^)(${this.usedWordsData?.join('|')})([^\\p{L}\\p{N}]|$))`;
        const pattern = new RegExp(regex, 'ugi');

        if (this.mode === 'not-used') {
          const unUsedWords = this.wordsToUse?.filter(item => !this.usedWordsData?.includes(item));
          return unUsedWords.join(', ');
        } else if (this.mode === 'used') {
          let unique = [...new Set(this.usedWordsData)];

          return unique.join(', ').replace(pattern, '<span class="strikethrough">$<words></span>');
        }

      } else {
        return this.wordsToUse.join(', ');
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>