<template>
  <the-new-header/>
  <div class="page-content meta-editor">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">

      <div class="new-container">
        <h1 class="page-title">{{ title }}</h1>
      </div>

      <div class="page-content new-container">
        <template v-if="currentApp?.store?.key === 'APP_STORE'">

          <template v-if="!isTablet && !isMobile">
            <div class="meta-editor-table-block">
              <div class="meta-editor-options-block-wrap">
                <div class="country-block">
                  <div class="search-locales-block">
                    <custom-inline-search v-if="tableMode === 'view'" placeholder="Search for Countries"
                                          @search-input-changed="countriesLocalesInputChanged"
                                          :with-result-slots="true"
                                          :min-input-length="0"
                                          :show-caret="true"
                                          :full-width-content="true"
                                          results-max-height="256px"
                                          :close-from-outside-counter="closeFromOutsideCounter"
                                          :initial-data="currentKeywordCountryName"
                                          :config="countryLocalesConfig"
                                          :search-results="countriesListComputed">

                      <template v-slot:heading-info>

                        <dynamic-image classes="country-flag"
                                       :width="18"
                                       :height="14"
                                       :size="32"
                                       :country-code="dropdownCurrentCountryData?.code"/>
                        {{ dropdownCurrentCountryData?.name }}
                      </template>

                      <template v-slot:results>
                        <div class="results-group"
                             v-if="countriesListLoaded">
                          <template
                            v-if="countriesListComputed?.length > 0">
                            <div v-for="country in countriesListComputed"
                                 @click="countryClickedHandler(country.code, country.name, country.id)"
                                 class="result-item">
                              <template v-if="country?.code">
                                <dynamic-image classes="country-flag"
                                               :width="18"
                                               :height="14"
                                               :size="32"
                                               :country-code="country?.code"/>
                              </template>
                              <span class="item-name">{{ country?.name }}</span>
                            </div>
                          </template>
                          <template v-else>
                            <div class="results-group">
                              <div class="text">No data found</div>
                            </div>
                          </template>
                        </div>
                      </template>
                    </custom-inline-search>
                  </div>

                  <div class="buttons-block">
                    <order-radaso-button :pre-selected="['aso-audit', 'metadata-optimization', 'full-aso-support']" />

                    <div v-if="userSubscription?.plan_name === 'Free'"
                         @click="openPlansModal"
                         class="download-button">
                      <svg-icon icon="download"/>
                    </div>

                    <a v-else class="download-button"
                       :href="fetchDownloadData()"
                       download>
                      <svg-icon icon="export-csv-icon"/>
                      <div class="tooltip-content">Download CSV</div>
                    </a>
                  </div>
                </div>
                <div class="meta-editor-options-block">
                  <div></div>
                  <div class="right-side">
                    <div class="versions-block">
                      <custom-select :options="savedMetasArray"
                                     :initial-data="'App store'"
                                     :close-dropdown="closeSavedMetaDropdown"
                                     :classes="'flat outlined'">

                        <template v-slot:heading>
                          <template
                            v-if="JSON.stringify(currentMetaVersion) !== '{}' && currentMetaVersion !== undefined">
                            {{ currentMetaVersion?.name }}
                          </template>
                          <template v-else>App Store Meta</template>
                        </template>

                        <template v-slot:results>
                          <div class="results-block">
                            <div class="results-item main-item"
                                 @click="changeAppMeta">App Store Meta
                            </div>
                            <template v-if="savedMetasArray?.length > 0">
                              <div class="results-item"
                                   v-for="metaItem in savedMetasArray">
                                <div class="item-inner"
                                     @click="changeAppMeta(metaItem)">
                                  <div class="meta-name">{{ metaItem?.name }}</div>
                                  <div class="meta-date">{{ metaItem?.updated_at }}</div>
                                </div>
                                <div class="delete-icon"
                                     @click="openMetaDeleteModal(metaItem)">
                                  <svg-icon icon="close"/>
                                </div>
                              </div>
                            </template>
                            <div class="no-results" v-else>No saved metas found</div>
                          </div>
                        </template>
                      </custom-select>
                    </div>

                    <div class="buttons-block">
                      <base-button height="36px"
                                   v-if="tableMode === 'view'"
                                   width="120px"
                                   class="btn-standard save-edit-btn"
                                   border-radius="4px"
                                   font-size="16px"
                                   @click="switchTableMode">
                        Edit Meta
                      </base-button>

                      <base-button height="36px"
                                   v-if="tableMode === 'edit'"
                                   width="140px"
                                   class="btn-standard save-edit-btn"
                                   border-radius="4px"
                                   font-size="16px"
                                   @click="saveCurrentVersion">
                        Save Meta
                      </base-button>

                      <base-button height="36px"
                                   v-if="tableMode === 'edit'"
                                   width="140px"
                                   class="btn-standard save-edit-btn outline"
                                   border-radius="4px"
                                   font-size="16px"
                                   @click="saveNewMetaClicked">
                        Save as new
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="metaTableDataLoaded">
                <meta-editor-table :table-mode="tableMode"
                                   :table-data="appMetaData"
                                   :words-to-use="appMetaData?.words_to_use"
                                   :used-words-data="appMetaData?.used_words"
                                   :store-data="storeAppMetaData"
                                   :classes="'upper-table'"
                                   @change-table-mode="changeTableMode"
                                   @blured-block-clicked="scrollToWords"
                                   @editable-table-value-changed="editableTableValueChanged"
                                   @clear-unused-words="clearUnusedWords"
                                   @add-all-words="addAllWords"/>
              </template>

              <template v-else>
                <div class="preloader-wrap">
                  <preloader-table-small loader-size="50px"></preloader-table-small>
                </div>
              </template>
            </div>

            <div class="meta-editor-tabs-content"
                 id="blured-scroll-anchor">
              <template v-if="!isTablet && !isMobile">
                <div class="tabs-heading">
                  <div class="tabs-heading-item"
                       :class="{active: setActiveClass('words')}"
                       @click="switchCurrentTab('words')">Words
                  </div>
                  <div class="tabs-heading-item"
                       :class="{active: setActiveClass('keywords')}"
                       @click="switchCurrentTab('keywords')">Keywords
                  </div>
                  <div class="tabs-heading-item"
                       :class="{active: setActiveClass('UncoveredKeywords')}"
                       @click="switchCurrentTab('UncoveredKeywords')">Uncovered Keywords
                  </div>
                  <div class="tabs-heading-item"
                       :class="{active: setActiveClass('CompetitorsMeta')}"
                       @click="switchCurrentTab('CompetitorsMeta')">Competitors Meta
                  </div>
                </div>
              </template>

              <template v-if="isTablet || isMobile">
                <div class="header-tabs-links">
                  <div class="mobile-tabs-content">
                    <div class="mobile-tabs-heading">
                      <div class="active-tab">
                        <span class="text">{{ detectCurrentTabName(currentTab) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <header-tabs v-if="false"
                             @dropdown-closed="tabDropdownOpened = false">
                  <div class="mobile-tabs-content"
                       :class="{active: tabDropdownOpened}">
                    <div class="mobile-tabs-heading">
                      <div class="active-tab"
                           @click="tabDropdownOpened = !tabDropdownOpened">
                        <span class="text">{{ detectCurrentTabName(currentTab) }}</span>

                        <div class="tab-icon">
                          <img src="@/assets/svg/arrow.svg" alt="Arrow icon">
                        </div>
                      </div>
                    </div>
                    <div class="mobile-tabs-list">
                      <div class="tabs-heading-item"
                           :class="{active: setActiveClass('words')}"
                           @click="switchCurrentTab('words')">Words
                      </div>
                      <div class="tabs-heading-item"
                           :class="{active: setActiveClass('keywords')}"
                           @click="switchCurrentTab('keywords')">Keywords
                      </div>
                      <div class="tabs-heading-item"
                           :class="{active: setActiveClass('UncoveredKeywords')}"
                           @click="switchCurrentTab('UncoveredKeywords')">Uncovered Keywords
                      </div>
                      <div class="tabs-heading-item"
                           :class="{active: setActiveClass('CompetitorsMeta')}"
                           @click="switchCurrentTab('CompetitorsMeta')">Competitors Meta
                      </div>
                    </div>
                  </div>
                </header-tabs>
              </template>

              <div class="tabs-content">
                <template v-if="componentTableDataLoaded">
                  <!--                    <keep-alive>-->
                  <component :is="currentComponent"
                             :country-code="dropdownCurrentCountryData?.code"
                             :countries-list="countriesList"
                             :current-country-code="dropdownCurrentCountryData?.code"
                             :current-country-name="dropdownCurrentCountryData?.name"
                             :current-country-id="dropdownCurrentCountryData?.id"
                             :current-redaction-id="currentRedactionId"
                             :current-filter-reset="currentFilterResetCounter"
                             :countriesList="currentMetaLocalesList"
                             :editable-metas="appMetaData"
                             @scroll-to-anchor="scrollToWords('instant')"
                             @keyword-deleted="updateTables"
                             @save-table-words="saveTableWords"
                             @add-all-words="addAllWords"></component>
                  <!--                    </keep-alive>-->
                </template>
                <template v-else>
                  <div class="preloader-wrap">
                    <preloader-table-small loader-size="50px"></preloader-table-small>
                  </div>
                </template>
              </div>
            </div>
          </template>

          <template v-else-if="isTablet || isMobile">
            <div class="mobile-notification-wrap">
              <div class="mobile-notification">
                <div class="notification-image">
                  <img src="@/assets/images/mobile-notification.svg"
                       width="181"
                       height="133"
                       alt="Mobile notification image"/>
                </div>
                <div class="notification-text">
                  Unfortunately, this tool is only available for the desktop version 😢
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="google-play-layout">
            <div class="text-block">
              <div class="gp-title">
                The Meta editor tool is currently only available for AppStore applications
              </div>
              <div class="gp-subtitle">
                We are actively working on making this feature available for Google Play
                applications to provide you
                with a seamless experience across both platforms.
              </div>
            </div>
          </div>
        </template>
      </div>
    </main>

    <custom-modal :opened="showNewMetaSave"
                  :max-width="520"
                  class-name="save-new-meta-modal"
                  @closeModal="metaVersionNameModalCancelled">
      <div class="modal-message">Enter the Metadata Version Name</div>

      <div class="meta-name-input-block">
        <input type="text"
               class="meta-name-input"
               placeholder="Version Name"
               autofocus
               v-model="newMetaName">
        <div class="error-block"
             v-if="newMetaVersionNameIsOkay() === false">
          You already have version with this name
        </div>
      </div>

      <template v-slot:footer-action>
        <div class="btn-block action-btns">
          <base-button height="40px"
                       width="144px"
                       font-size="15px"
                       border-radius="4px"
                       :mode="'btn-standard outline'"
                       @click="metaVersionNameModalCancelled">Cancel
          </base-button>

          <template v-if="saveVersionClickOrigin !== 'from-table'">
            <base-button height="40px"
                         width="144px"
                         font-size="15px"
                         border-radius="4px"
                         :disabled="newMetaName?.length === 0 || newMetaVersionNameIsOkay() === false"
                         :mode="'btn-standard'"
                         @click="switchTableMode('new')">Save
            </base-button>
          </template>
          <template v-else>
            <base-button height="40px"
                         width="144px"
                         font-size="15px"
                         border-radius="4px"
                         :disabled="newMetaName?.length === 0 || newMetaVersionNameIsOkay() === false"
                         :mode="'btn-standard'"
                         @click="saveOutterData">Save
            </base-button>
          </template>
        </div>
      </template>
    </custom-modal>

    <custom-modal :opened="showDeleteMeta"
                  :max-width="520"
                  class-name="save-new-meta-modal"
                  @closeModal="closeModal('showDeleteMeta')">
      <div class="modal-message">Do you really want to delete the metadata {{ metaItemToDelete?.name }}</div>

      <template v-slot:footer-action>
        <div class="btn-block action-btns">
          <base-button height="40px"
                       width="144px"
                       font-size="15px"
                       border-radius="4px"
                       :mode="'btn-standard outline'"
                       @click="closeModal('showDeleteMeta')">Cancel
          </base-button>

          <base-button height="40px"
                       width="144px"
                       font-size="15px"
                       border-radius="4px"
                       :mode="'btn-standard'"
                       @click="deleteMetaClicked">Delete
          </base-button>

        </div>
      </template>
    </custom-modal>

    <teleport to="body">
      <sliding-window :show-panel="showSidePanel"
                      classes="keywords-table-panel"
                      @close-panel="closeSlidePanel"
                      v-if="componentTableDataLoaded && showSidePanel !== 0">
        <Keywords :country-code="dropdownCurrentCountryData?.code"
                  :countries-list="countriesList"
                  :current-country-code="dropdownCurrentCountryData?.code"
                  :current-country-name="dropdownCurrentCountryData?.name"
                  :current-country-id="dropdownCurrentCountryData?.id"
                  :current-redaction-id="currentRedactionId"
                  :current-filter-reset="currentFilterResetCounter"
                  :countriesList="currentMetaLocalesList"
                  :table-headers="keywordsPanelHeaders"
                  @keyword-deleted="updateTables"
                  @save-table-words="saveTableWords"
                  :search-keyword-value="currentWordSelectedValue"
                  :table-options="keywordsTableOptions"
                  :show-filters="false"
                  :show-search="false"/>
      </sliding-window>
    </teleport>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {httpRequest} from "@/api";
import {deleteCookie, formatDate, getCookie, getFlagEmoji, setCookie} from "@/utils/utils";
import {AppMetaDataObjectCreator} from "@/utils/factories";
import NotifyMeComponent from "@/views/MetaEditor/NotifyMeComponent/index.vue";
import CustomInlineSearch from "@/components/Forms/CustomInlineSearch/index.vue";
import Words from "@/views/MetaEditor/components/Words/index.vue";
import Keywords from "@/views/MetaEditor/components/Keywords/index.vue";
import UncoveredKeywords from "@/views/MetaEditor/components/UncoveredKeywords/index.vue";
import CompetitorsMeta from "@/views/MetaEditor/components/CompetitorsMeta/index.vue";
import MetaEditorTable from "@/views/MetaEditor/components/MetaEditorTable/index.vue";
import WordsToUse from "@/views/MetaEditor/components/WordsToUse/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import CustomSelect from "@/components/Forms/CustomSelect/index.vue";
import SlidingWindow from "@/components/SlidingWindow/index.vue";
import {wordsTableConfig} from "@/configs/page-tables-configs/keywords-table-panel";
import CustomDropdownAbsolute from "@/components/UI/CustomDropdownAbsolute/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: 'MetaEditor',
  beforeRouteLeave(to, from, next) {
    if (this.tableMode === 'edit') {
      const answer = window.confirm('Changes you made may not be saved.');
      if (!answer) {
        next(false);
      } else {
        next();
      }
    } else {
      next();
    }
  },
  data() {
    return {
      title: '',
      countryLocalesConfig: {
        border: 'none',
        borderRadius: '4px',
        boxShadow: '0px 4px 8px rgba(160, 190, 221, 0.3)',
        height: '36px',
      },
      countryLocaleSearchInputValue: '',
      currentKeywordCountryCode: '',
      currentKeywordCountryId: '',
      currentKeywordCountryName: '',
      countriesList: [],
      dropdownCurrentCountryData: {
        name: '',
        code: '',
        id: '',
      },
      countriesListLoaded: false,
      metaTableDataLoaded: false,
      componentTableDataLoaded: false,
      closeFromOutsideCounter: 0,
      currentTab: 'words',
      // currentTab: 'UncoveredKeywords',
      currentComponent: 'Words',
      // currentComponent: 'UncoveredKeywords',
      tabDropdownOpened: false,
      tableMode: 'view',
      appMetaData: {},
      storeAppMetaData: {},
      editedAppMetaData: {},
      currentRedactionId: null,
      localeCodes: {},
      localesData: {},
      wordsToUseObject: {},
      // betaTesterCookie: null,
      currentFilterResetCounter: 0,
      currentMetaLocalesList: [],
      showNewMetaSave: false,
      showDeleteMeta: false,
      newMetaName: '',
      savedMetasArray: [],
      closeSavedMetaDropdown: 0,
      currentMetaVersion: {},
      metaItemToDelete: {},
      showSidePanel: 0,
      currentWordSelectedValue: null,
      keywordsPanelHeaders: wordsTableConfig,
      keywordsTableOptions: {
        noPagination: true,
        per_page: 10000,
      },
      saveVersionClickOrigin: 'menu',
      usedWordsObject: {},
    }
  },
  components: {
    OrderRadasoButton,
    SvgIcon,
    'custom-select': CustomSelect,
    NotifyMeComponent,
    MetaEditorTable,
    WordsToUse,
    Words,
    Keywords,
    UncoveredKeywords,
    CompetitorsMeta,
    'custom-inline-search': CustomInlineSearch,
    'preloader-table-small': PreloaderTableSmall,
    SlidingWindow,
    CustomDropdownAbsolute,
  },
  methods: {
    async fetchCountriesList() {
      this.countriesListLoaded = false;
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.SEARCH_KEYWORDS_COUNTRIES + '/' + this.currentApp?.id + '/countries');

      if (result?.countries) {
        this.countriesList = [...result?.countries]?.sort((a, b) => (a?.name > b?.name) ? 1 : -1);
      }

      this.countriesListLoaded = true;
    },
    async countryClickedHandler(code, name, id) {
      let answer = true;
      const currCountryData = {
        name: name,
        code: code,
        id: id,
      }

      this.editedAppMetaData = {};
      this.closeSlidePanel();

      if (this.tableMode === 'edit') {
        answer = window.confirm('Changes you made may not be saved.');

        if (answer) {
          this.currentRedactionId = null;
          this.tableMode = 'view';
          this.dropdownCurrentCountryData = {...currCountryData};
          this.closeFromOutsideCounter++;

          this.countryLocaleSearchInputValue = '';
          this.currentFilterResetCounter++;
          await this.fetchMetaBundleData(code);
        } else {
          this.closeFromOutsideCounter++;
        }
      } else {
        this.currentRedactionId = null;
        this.dropdownCurrentCountryData = {...currCountryData};
        this.closeFromOutsideCounter++;

        this.countryLocaleSearchInputValue = '';
        this.currentFilterResetCounter++;
        await this.fetchMetaBundleData(code);
      }
    },
    async fetchMetaBundleData(countryCode) {
      this.localesData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.user.COUNTRY_LOCALES + `/${this.currentApp?.store?.key}/${countryCode}/no`);
      this.countriesListLoaded = true;
      await this.fetchAppSavedMetas();
      this.componentTableDataLoaded = false;
      await this.fetchAppEditableMetas(this.setLocales(this.localesData?.popular), 'store');
      this.setCurrentMetaVersion();
      await this.fetchAppEditableMetas(this.setLocales(this.localesData?.popular));
      this.localeCodes = {...this.localesData?.popular};
      this.componentTableDataLoaded = true;
    },
    async fetchAppSavedMetas() {
      const url = `?app_id=${this.currentApp?.id}&country_code=${this.dropdownCurrentCountryData?.code}`;
      const savedMetasArray = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_APP_METAS + url);

      if (savedMetasArray) {
        savedMetasArray?.sort((a, b) => (a?.updated_at < b?.updated_at) ? 1 : -1);
      }

      this.savedMetasArray = savedMetasArray?.map(item => {
        let updated_at = item?.updated_at?.split(' ');
        let time = updated_at[1];
        let date = formatDate(updated_at[0], 'day-month-year');
        return {
          ...item,
          updated_at: `${date} ${time}`,
        }
      });

    },
    async fetchAppEditableMetas(localesArray, mode) {
      let url = `?app_id=${this.currentApp?.id}`;

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined' && mode !== 'store') {
        url += `&redaction_id=${this.currentRedactionId}`;
      }

      localesArray.forEach((locale, idx) => {
        url += `&locale_codes[]=${locale.code}`;
      });

      const appMetaData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_APP_EDITABLE_METAS + url);
      this.appMetaData = AppMetaDataObjectCreator(appMetaData);
      this.$store.dispatch('SET_EDITABLE_META', this.appMetaData);

      this.currentMetaLocalesList = Object.values(this.appMetaData?.metas)?.map(item => {
        return {
          localeName: item?.locale_name,
          localeCode: item?.locale_code,
        }
      });

      if (mode === 'store') {
        this.storeAppMetaData = {...this.appMetaData};
      }

      this.metaTableDataLoaded = true;
    },
    async saveAppMeta(mode = 'default', usedWordsObject = null) {
      let tempAppMetaData = {...this.appMetaData};
      let formData = new FormData();
      let versionName = this.newMetaName || this.currentMetaVersion?.name;
      this.closeSlidePanel();
      formData.append('app_id', this.currentApp?.id);
      formData.append('country_code', this.dropdownCurrentCountryData?.code);

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined' && this.newMetaName === '') {
        formData.append('redaction_id', this.currentRedactionId ?? '');
      }

      if (!versionName) {
        formData.append('redaction_name', 'Untitled');
      } else {
        formData.append('redaction_name', versionName);
      }

      let metas;
      if (mode === 'words-update') {
        metas = this.appMetaData?.metas;
      } else {
        metas = this.editedAppMetaData?.metas;
      }

      for (const key in metas) {
        formData.append(`metas[${key}][title]`, metas[key]?.title?.text);
        formData.append(`metas[${key}][sub_title]`, metas[key]?.sub_title?.text);

        tempAppMetaData.metas[key].title.text = metas[key]?.title?.text;
        tempAppMetaData.metas[key].sub_title.text = metas[key]?.sub_title?.text;

        for (const subKey in metas[key]?.keywords?.list) {
          if (metas[key]?.keywords?.list[subKey] !== '') {
            formData.append(`metas[${key}][keywords][]`, metas[key]?.keywords?.list[subKey]);
          }
        }

        if (usedWordsObject && usedWordsObject?.localeCode === key) {
          let unique = new Set([...tempAppMetaData.metas[key].words_to_use, ...usedWordsObject.wordsArray]);

          if (usedWordsObject?.mode === 'clear') {
            unique = new Set([...usedWordsObject.wordsArray]);
          }

          tempAppMetaData.metas[key].words_to_use = [...unique];

          for (const subKey in metas[key]?.words_to_use) {
            formData.append(`metas[${key}][words_to_use][]`, metas[key]?.words_to_use[subKey]);
          }
        } else {
          for (const subKey in metas[key]?.words_to_use) {
            if (!usedWordsObject?.wordsArray?.includes(metas[key]?.words_to_use[subKey])) {
              formData.append(`metas[${key}][words_to_use][]`, metas[key]?.words_to_use[subKey]);
            }
            // formData.append(`metas[${key}][words_to_use][]`, metas[key]?.words_to_use[subKey]);
          }
        }
      }

      this.appMetaData = {...tempAppMetaData};

      const saveAppMetaRequest = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.SAVE_APP_METAS, formData);

      if (saveAppMetaRequest?.id) {
        this.currentRedactionId = saveAppMetaRequest?.id;

        let versionName = (saveAppMetaRequest?.name || this.currentMetaVersion?.name || this.newMetaName) ?? 'Untitled';
        this.currentMetaVersion = {
          name: versionName,
          id: saveAppMetaRequest?.id,
        };
      }

      await this.fetchAppEditableMetas(this.setLocales(this.localesData?.popular), mode);

      this.newMetaName = '';
    },
    async switchTableMode(mode) {
      if (this.tableMode === 'view') {
        this.tableMode = 'edit';
      } else {
        this.componentTableDataLoaded = false;
        this.tableMode = 'view';
        this.showNewMetaSave = false;

        if (mode === 'new') {
          deleteCookie('currentMetaRedactionId');
          this.currentRedactionId = null;
        }

        if (JSON.stringify(this.editedAppMetaData) === '{}') {
          await this.saveAppMeta('words-update');
        } else {
          await this.saveAppMeta();
        }
        await this.fetchAppSavedMetas();
        this.setCurrentMetaVersion();


        this.editedAppMetaData = {};
        this.componentTableDataLoaded = true;
      }
    },
    async saveCurrentVersion() {
      let versionName = this.newMetaName || this.currentMetaVersion?.name;

      if (!versionName) {
        this.showNewMetaSave = true;
      } else {
        await this.switchTableMode();
      }
    },
    async editableTableValueChanged(e) {
      const changedTableData = {
        localeCode: e.localeCode,
        itemType: e.itemType,
        newValue: e.newValue
      };

      let tempTableData = {...this.appMetaData};

      if (e?.itemType === 'words_to_use') {
        let usedWordsData = [...tempTableData.metas[changedTableData.localeCode].used_words];

        if (typeof changedTableData?.newValue !== 'string' && typeof changedTableData?.newValue !== 'number') {
          usedWordsData = [...tempTableData.metas[changedTableData.localeCode].used_words, ...changedTableData?.newValue];
        } else {
          changedTableData?.newValue.split(' ').forEach(item => {
            usedWordsData.push(item);
          });
        }

        tempTableData.metas[changedTableData.localeCode].words_to_use = [...new Set(usedWordsData)]?.filter(item => item !== '');
      } else {
        if (e?.itemType !== 'keywords') {
          tempTableData.metas[changedTableData.localeCode][changedTableData.itemType].text = changedTableData.newValue;
        } else {

          if (typeof changedTableData.newValue === 'string') {
            //if adding keyword is string with spaces, ex. Currency converter
            const splittedBySpace = changedTableData.newValue?.split(' ');
            if (splittedBySpace?.length > 0) {
              tempTableData.metas[changedTableData.localeCode][changedTableData.itemType].list = splittedBySpace;
            } else {
              //if adding keyword is string with no spaces, ex. Currency
              tempTableData.metas[changedTableData.localeCode][changedTableData.itemType].list = new Array(changedTableData.newValue);
            }

          } else {

            let tempKeywordArray = [];
            for (const key in changedTableData.newValue) {
              const splittedBySpace = changedTableData.newValue[key]?.split(' ');

              if (splittedBySpace?.length > 0) {
                tempKeywordArray = [...tempKeywordArray, ...splittedBySpace];
              } else {
                tempKeywordArray.push(changedTableData.newValue[key]);
              }
            }

            tempTableData.metas[changedTableData.localeCode][changedTableData.itemType].list = [...tempKeywordArray];
            // tempTableData.metas[changedTableData.localeCode][changedTableData.itemType].list = [...changedTableData.newValue];
          }

        }
      }
      this.editedAppMetaData = {...tempTableData};

      if (e?.mode === 'no-reload') {
        await this.saveAppMeta('no-reload');
      }
    },
    async saveTableWords(usedWordsObject) {
      this.scrollToWords();

      let versionName = this.newMetaName || this.currentMetaVersion?.name;

      if (JSON.stringify(this.editedAppMetaData) === '{}' && !versionName) {
        this.showNewMetaSave = true;
        this.saveVersionClickOrigin = 'from-table';
        this.usedWordsObject = {...usedWordsObject};
      } else {
        await this.saveAppMeta('words-update', usedWordsObject);
        await this.fetchAppSavedMetas();

        this.saveVersionClickOrigin = 'menu';
        this.usedWordsObject = {};
      }
    },
    async saveOutterData() {
      this.showNewMetaSave = false;
      await this.saveAppMeta('words-update', this.usedWordsObject);
      await this.fetchAppSavedMetas();

      this.setCurrentMetaVersion();

      this.usedWordsObject = {};
    },
    async updateTables(e) {
      if (e?.action === 'close-panel') {
        this.closeSlidePanel();
        window.onscroll = function () {
        };
      }
      this.componentTableDataLoaded = false;
      await this.fetchMetaBundleData(this.currentKeywordCountryCode);
      this.componentTableDataLoaded = true;
    },
    async clearUnusedWords(event) {
      this.componentTableDataLoaded = false;

      await this.saveAppMeta('words-update', {
        ...event,
        mode: 'clear'
      });

      this.componentTableDataLoaded = true;
    },
    async addAllWords(event) {
      const stringMaxLength = 100;
      let wordsArray = [];
      let wordsToUseArray = [];
      let usedWordsArray = [];
      let tempTableData = {...this.appMetaData};
      if (!tempTableData?.metas[event?.localeCode]?.keywords?.list) {
        return;
      }

      wordsArray = [...tempTableData?.metas[event?.localeCode]?.keywords?.list];

      const filteredWords = event?.wordsArray?.map(word => {
        const pattern = new RegExp(`[^\\p{L}\\p{N}]`, 'ugi');
        word = word.replace(pattern, '');
        if (word && word !== '') {
          return word;
        }
      }).filter(item => !!item);

      for (const key in filteredWords) {
        const joinedStrLength = wordsArray?.join(',')?.length;
        const addedWordLength = event?.wordsArray[key]?.length;

        if ((joinedStrLength + addedWordLength) < stringMaxLength) {
          if (!wordsArray?.includes(filteredWords[key])) {
            wordsArray.push(filteredWords[key]);
            usedWordsArray.push(filteredWords[key]);
          }
        } else {
          wordsToUseArray.push(filteredWords[key]);
        }
      }
      const uniqueWords = new Set(wordsArray);
      tempTableData.metas[event.localeCode].keywords.list = [...uniqueWords];
      tempTableData.metas[event.localeCode].used_words = [...tempTableData.metas[event.localeCode].used_words, ...usedWordsArray];
      this.appMetaData = {...tempTableData};

      await this.saveAppMeta('words-update', event);
    },
    async deleteMetaClicked() {
      let formData = new FormData();
      formData.append('redaction_ids[]', this.metaItemToDelete?.id);
      await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.DELETE_SAVED_META, formData);

      if (this.metaItemToDelete?.id === this.currentRedactionId) {
        this.currentRedactionId = null;
        deleteCookie('currentMetaRedactionId');
        this.componentTableDataLoaded = false;
      }

      this.showDeleteMeta = false;
      await this.fetchAppSavedMetas();
      this.setCurrentMetaVersion();

      await this.fetchAppEditableMetas(this.setLocales(this.localesData?.popular));
      this.componentTableDataLoaded = true;
      // if (this.metaItemToDelete?.id === this.currentRedactionId) {
      //   this.componentTableDataLoaded = true;
      // }

    },
    async changeAppMeta(metaItem) {
      if (metaItem) {
        this.currentRedactionId = metaItem?.id;
        setCookie('currentMetaRedactionId', metaItem?.id, process.env.VUE_APP_COOKIE_DOMAIN, 0.5);
      } else {
        this.currentRedactionId = null;
        deleteCookie('currentMetaRedactionId');
      }

      this.currentMetaVersion = this.savedMetasArray?.find(meta => meta?.id === metaItem?.id);
      this.closeSavedMetaDropdown++;
      this.componentTableDataLoaded = false;
      await this.fetchAppEditableMetas(this.setLocales(this.localesData?.popular));
      setTimeout(() => {
        this.componentTableDataLoaded = true;
      }, 150);

      this.closeSlidePanel();
    },
    countriesLocalesInputChanged(value) {
      this.countryLocaleSearchInputValue = value;
    },
    switchCurrentTab(tabName) {
      this.currentTab = tabName;
      this.currentComponent = tabName;
      this.tabDropdownOpened = false;
      this.closeSlidePanel();
    },
    setActiveClass(tabName) {
      return this.currentTab === tabName;
    },
    detectCurrentTabName(tabName) {
      const tabNames = {
        'words': 'Words',
        'keywords': 'Keywords',
        'competitors-meta': 'Competitors Meta',
        'dropped-keywords': 'Dropped Keywords',
        'saved-meta': 'Saved Meta',
      };
      return tabNames[tabName];
    },
    setLocales(obj) {
      let localesArr = [];
      for (const key in obj) {
        localesArr.push({
          code: key,
          name: obj[key],
        })
      }
      return localesArr;
    },
    flagEmoji(val) {
      return getFlagEmoji(val);
    },
    fetchDownloadData(appId) {
      const currentAppId = appId ?? this.currentApp?.id;
      const redactionId = this.currentRedactionId ?? '';
      let url = `?app_id=${currentAppId}&redaction_id=${redactionId}`;
      for (const key in this.localeCodes) {
        url += `&locale_codes[]=${key}`;
      }

      return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.META_CREATOR + url;
    },
    scrollToWords(mode = 'smooth') {
      document.querySelector('#blured-scroll-anchor').scrollIntoView({
        behavior: mode
      });
    },
    changeTableMode() {
      this.switchTableMode();
    },
    handleBeforeUnload(event) {
      if (this.tableMode === 'edit') {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    saveNewMetaClicked() {
      this.newMetaName = '';
      this.showNewMetaSave = true;
    },
    openMetaDeleteModal(deleteItem) {
      this.metaItemToDelete = {...deleteItem};
      this.showDeleteMeta = true;
    },
    setCurrentMetaVersion() {
      let tempCurrentMetaVersion = {};
      if (!this.currentRedactionId) {
        if (this.savedMetasArray?.length > 0) {
          tempCurrentMetaVersion = {...this.savedMetasArray[0]};
          this.currentRedactionId = tempCurrentMetaVersion?.id;
        } else {
          this.currentRedactionId = null;
          deleteCookie('currentMetaRedactionId');
        }
      } else {
        const meta = this.savedMetasArray?.find(item => item?.id === this.currentRedactionId);
        tempCurrentMetaVersion = {...meta};
      }

      this.currentMetaVersion = {
        name: tempCurrentMetaVersion?.name,
        id: tempCurrentMetaVersion?.id,
        updated_at: tempCurrentMetaVersion?.updated_at,
      }
    },
    newMetaVersionNameIsOkay() {
      const metaIdx = this.savedMetasArray?.findIndex(item => item?.name === this.newMetaName);
      return metaIdx < 0;
    },
    metaVersionNameModalCancelled() {
      this.newMetaName = '';
      this.closeModal('showNewMetaSave');
    },
    closeSlidePanel() {
      this.showSidePanel = 0;
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  },
  async mounted() {
    // this.betaTesterCookie = getCookie('betaTesterCookie');
    this.currentRedactionId = getCookie('currentMetaRedactionId');

    // if (this.betaTesterCookie) {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.currentKeywordCountryCode = this.currentCountry?.code;
    this.currentKeywordCountryId = this.currentCountry?.id;
    this.currentKeywordCountryName = this.currentCountry?.name;

    const tempDataObj = {
      name: this.currentKeywordCountryName,
      code: this.currentKeywordCountryCode,
      id: this.currentKeywordCountryId,
    };

    this.dropdownCurrentCountryData = {...tempDataObj};
    await this.fetchCountriesList();
    this.closeFromOutsideCounter++;
    this.countryLocaleSearchInputValue = '';
    await this.fetchMetaBundleData(this.currentKeywordCountryCode);

    this.setCurrentMetaVersion();
    // }
  },
  beforeDestroy() {
    // window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'userData',
      'sidebarIsCollapsed',
      'currentCountry',
      'currentCountryManual',
      'isTablet',
      'isMobile',
      'userSubscription',
    ]),
    ...mapGetters('metaData', [
      'currentWordSelected',
    ]),
    countriesListComputed() {
      const tempArray = [...this.countriesList];
      const loweredInput = this.countryLocaleSearchInputValue?.toLowerCase();
      return tempArray?.filter(country => country?.name?.toLowerCase()?.includes(loweredInput))
    },
  },
  watch: {
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.currentRedactionId = null;
        deleteCookie('currentMetaRedactionId');
        this.metaTableDataLoaded = false;
        this.componentTableDataLoaded = false;
        deleteCookie('metadataStoreData');

        this.currentKeywordCountryCode = this.currentCountry?.code;
        this.currentKeywordCountryId = this.currentCountry?.id;
        this.currentKeywordCountryName = this.currentCountry?.name;
        const tempDataObj = {
          name: this.currentKeywordCountryName,
          code: this.currentKeywordCountryCode,
          id: this.currentKeywordCountryId,
        };

        this.dropdownCurrentCountryData = {...tempDataObj};
        this.closeSlidePanel();
        await this.fetchCountriesList();
        this.closeFromOutsideCounter++;
        this.countryLocaleSearchInputValue = '';
        await this.fetchMetaBundleData(this.currentKeywordCountryCode);
        this.metaTableDataLoaded = true;
        this.componentTableDataLoaded = true;
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        deleteCookie('metadataStoreData');
        this.currentRedactionId = null;
        deleteCookie('currentMetaRedactionId');
        this.metaTableDataLoaded = false;
        this.componentTableDataLoaded = false;
        this.currentKeywordCountryCode = newVal?.code;
        this.currentKeywordCountryId = newVal?.id;
        this.currentKeywordCountryName = newVal?.name;

        const tempDataObj = {
          name: newVal?.name,
          code: newVal?.code,
          id: newVal?.id,
        };

        this.dropdownCurrentCountryData = {...tempDataObj};
        this.closeSlidePanel();
        await this.fetchCountriesList();
        await this.countryClickedHandler(newVal?.code, newVal?.name, newVal?.id);
        this.metaTableDataLoaded = true;
        this.componentTableDataLoaded = true;
      }
    },
    currentWordSelected(newVal, oldVal) {
      this.currentWordSelectedValue = newVal;
      this.showSidePanel++;
    },
    newMetaName(newVal, oldVal) {
      this.newMetaVersionNameIsOkay();
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
<style lang="scss">
//sticky header hack
.keywords-table-panel {
  .table-wrap {
    height: 100%;
  }

  .words-table {
    height: 100%;
    > div {
      height: 100%;
      table {
        max-height: 100%;
      }
    }
  }
}

.meta-editor-tabs-content {
  .advanced-custom-data-table.words-custom-table {
    max-height: calc(100vh - 280px);
  }
}
</style>