<template>
  <div class="table-wrap">
    <div class="search-and-filter-block"
         v-show="filtersLoaded">

      <div class="filters-options-block">
        <div v-if="showFilters" class="filters-block">
          <div class="filter-item-wrap filters-trigger">
            <custom-dropdown :min-width="'92px'"
                             :with-icon="false"
                             :no-border="true"
                             :with-shadow="true"
                             :dropdown-width="'295px'"
                             :close-dropdown="closeFiltersTrigger"
                             :classes="'filters-btn'">
              <template v-slot:header>
                Filters
              </template>
              <template v-slot:content>
                <div class="select-options">
                  <div class="option"
                       v-for="(item, idx) in tableFilters"
                       :key="idx">
                    <svg-icon icon="plus"
                              class="filter-marker"
                              :class="{active: item.isActive}"/>
                    <data-table-checkbox :checked="item.isActive"
                                         @input-checked="filterChecked(item)">
                      {{ item.text }}
                    </data-table-checkbox>
                  </div>
                </div>
              </template>
            </custom-dropdown>
          </div>

          <translate-tool :active="translateIsActive" @translate-clicked="translateClickHandler"/>

          <div class="filter-item-wrap popularity-filter"
               v-if="tableFilters.popularity.isActive">
            <range-input :min-width="'168px'"
                         :no-presets="true"
                         :close-dropdown="closeAllDropdownsFlag"
                         @filter-applied="filterApplied($event, 'range_sap')"
                         :min-value="filtersObject.min_sap"
                         :max-value="filtersObject.max_sap"
                         :initial-data="filtersInitObject?.sap ?? null"
                         :opened-from-outside="tableFilters.popularity.opened">Popularity
            </range-input>
          </div>

          <div class="filter-item-wrap impressions-filter"
               v-if="tableFilters.dailyImpressions.isActive">
            <range-input :min-width="'168px'"
                         :no-presets="true"
                         :close-dropdown="closeAllDropdownsFlag"
                         custom-max-width="80px"
                         @filter-applied="filterApplied($event, 'range_impression')"
                         :min-value="filtersObject.min_daily_impression"
                         :max-value="filtersObject.max_daily_impression"
                         :initial-data="filtersInitObject?.impressions ?? null"
                         :opened-from-outside="tableFilters.dailyImpressions.opened">Daily Impr.
            </range-input>
          </div>

          <div class="filter-item-wrap character-filter"
               v-if="tableFilters.rank.isActive">
            <range-input :min-width="'168px'"
                         :no-presets="true"
                         :close-dropdown="closeAllDropdownsFlag"
                         custom-max-width="80px"
                         @filter-applied="filterApplied($event, 'range_rank')"
                         :min-value="filtersObject.min_rank"
                         :max-value="filtersObject.max_rank"
                         :initial-data="filtersInitObject?.rank ?? null"
                         :opened-from-outside="tableFilters.rank.opened">Rank
            </range-input>
          </div>

        </div>

        <div v-if="showSearch" class="search-keywords-block">
          <basic-search-input placeholder="Search by tracked keywords"
                              :hide-results-block="true"
                              @search-input-changed="searchKeywordsHandler"
                              :clear-input="searchKeywordsInput">

            <template v-slot:search-icon>
              <template v-if="!showKeywordsLoader">
                <svg-icon v-if="searchKeywordsInput?.length === 0"
                          class="search-icon"
                          icon="search-solid"/>

                <svg-icon v-else
                          @click="clearKeywordsSearchInput"
                          class="close-icon"
                          icon="close"/>
              </template>
            </template>
          </basic-search-input>
        </div>
      </div>

    </div>

    <template v-if="keywordsLoaded">
      <div class="words-table keywords"
           v-if="wordsTableData?.length > 0">

        <data-table :headers="headers"
                    :items="paginatedTrackedKeywords"
                    :pagination="tablePagination"
                    :sorting-meta="sortingMeta"
                    :items-per-page="tableOptions?.per_page ?? +tablePagination.per_page"
                    :rows-per-page-items="[50, 100, 250, 500, 1000]"
                    :fixed-width="true"
                    :show-actions="false"
                    :sorting-keys="{direction: 'order', sorting: 'sort_by', paginate: 'per_page'}"
                    :no-pagination="tableOptions?.noPagination"
                    :show-per-page-selector="true"
                    :table-classes="'words-custom-table'"
                    @check-all-clicked="checkAllClicked"
                    :check-all-changed="checkAllChanged"
                    :scroll-after-click="false"
                    @update-sort="updateSort">

          <template v-slot:expanded-header>&nbsp;with&nbsp;<span style="text-transform: capitalize;">{{searchKeywordValue}}</span></template>

          <template v-slot:select-all
                    v-if="keywordsTrackingChecked?.length >= 1 && keywordsTrackingChecked.length >= tablePagination?.per_page">
            <tr class="select-all-row">
              <td class="select-all-inner-block"
                  colspan="5">
                <template v-if="keywordsTrackingChecked?.length !== wordsTableData?.length">
                  <div class="inner-content">
                    <div class="select-all"
                         @click="selectAllKeywords">Select All Pages
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="inner-content">
                    <div class="select-all"
                         @click="unSelectAllKeywords">Unselect All Pages
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </template>

          <template v-slot:multi-delete>
            <div class="multi-select-container">
                  <span class="selected-count"
                        v-if="keywordsTrackingChecked?.length > 0">{{
                    keywordsTrackingChecked?.length
                    }}/{{ wordsTableData?.length }}</span>

              <div class="multi-select-block"
                   v-if="keywordsTrackingChecked?.length >= 1"
                   @click="multiCopy">

                <div class="content-block with-tooltip copy-text">
                  <div class="icon-wrap">
                    <svg-icon icon="copy"></svg-icon>
                  </div>

                  <div class="tooltip">
                    <div class="text">
                      <template v-if="showCopyActiveFlag">
                        Copied!
                      </template>
                      <template v-else>
                        Copy
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <div class="multi-select-block"
                   v-if="keywordsTrackingChecked?.length >= 1"
                   @click="keywordsMultiDelete">
                <div class="content-block with-tooltip"
                     :class="{'decline-keywords': !showDeclinedActiveFlag, 'declined': showDeclinedActiveFlag}">
                  <template v-if="showDeclinedActiveFlag">
                    <div class="icon-wrap plus-circle red-icon">
                      <svg-icon icon="delete-circle-icon"></svg-icon>
                    </div>

                    <div class="tooltip">
                      <div class="text">Deleted</div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="icon-wrap plus-circle">
                      <svg-icon icon="delete-circle-icon"></svg-icon>
                    </div>

                    <div class="tooltip">
                      <div class="text">Delete these keywords</div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:items="{item}">
            <td v-for="headerItem in headers"
                :style="{width: headerItem.width}"
                :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem?.headingClass]">
              <div class="inner-content">

                <template v-if="headerItem?.value !== 'tools' && headerItem?.value !== 'delete-tools'">
                  <template v-for="(tdItem, tdIndex) in item"
                            :key="tdIndex">

                    <template v-if="tdIndex === headerItem.value">
                      <data-table-checkbox v-if="headerItem.hasCheckbox"
                                           @input-checked="keywordsTableInputChecked($event, item)"
                                           :checked="getKeywordCheckedValue(item.id)"
                      />

                      <div v-if="headerItem?.value === 'keyword'"
                           class="word-container pos-relative">
                        <template v-if="detectTextOverflow(tdItem)">
                          <custom-tooltip width="200px"
                                          direction-horizontal="center">
                            <template v-slot:slotTrigger>
                              <div class="block-content text-overflow keyword-overflow">{{ tdItem }}</div>
                            </template>
                            <template v-slot:slotContent>
                              <span>{{ tdItem }}</span>
                              <div v-show="item.translate" class="translated-block" v-html="item.translate"></div>
                            </template>
                          </custom-tooltip>
                        </template>
                        <template v-else>
                          <div class="block-content text-overflow keyword-overflow">
                            <span>{{ tdItem }}</span>
                            <div v-show="item.translate" class="translated-block" v-html="item.translate"></div>
                          </div>
                        </template>

                        <div class="tooltips-container">
                          <div class="mr-12 tool-item visible-on-hover">
                            <translate-one-tool :set-result-to="item" :to-translate-string="tdItem"></translate-one-tool>
                          </div>

                          <custom-tooltip v-if="false"
                                          :classes="'tool-item hover-bg-blue translate'"
                                          :link-url="'https://translate.google.com/?sl=auto&tl=en&text=' + item.keyword"
                                          width="80px">
                            <template v-slot:slotTrigger>
                              <svg-icon icon="translate"/>
                            </template>
                            <template v-slot:slotContent>
                              Translate
                            </template>
                          </custom-tooltip>

                          <custom-tooltip :classes="'tool-item hover-bg-green live-search visible-on-hover'"
                                          direction-horizontal="center"
                                          @click="liveSearchToolClicked(item)">
                            <template v-slot:slotTrigger>
                              <svg-icon icon="livesearch-icon-new"
                                        class="live-search-icon"/>
                            </template>
                            <template v-slot:slotContent>
                              Live Search
                            </template>
                          </custom-tooltip>

                          <custom-tooltip :classes="'tool-item hover-bg-yellow hints visible-on-hover'"
                                          :style="{minWidth: '100px'}"
                                          @click="autoSuggestToolClicked(item)">
                            <template v-slot:slotTrigger>
                              <svg-icon icon="autosuggest-icon-new"
                                        class="autosuggest-icon"/>
                            </template>
                            <template v-slot:slotContent>
                              Autosuggest
                            </template>
                          </custom-tooltip>

                          <custom-tooltip :classes="'tool-item hover-bg-red delete visible-on-hover'"
                                          width="60px"
                                          @click="keywordDeleteButtonClicked(item)">
                            <template v-slot:slotTrigger>
                              <svg-icon icon="delete-icon"/>
                            </template>
                            <template v-slot:slotContent>
                              Delete
                            </template>
                          </custom-tooltip>

                          <custom-tooltip direction-horizontal="left"
                                          direction-vertical="bottom"
                                          :straight="true"
                                          width="300px"
                                          max-height="350px"
                                          :with-padding="true"
                                          :delayed-closing="true"
                                          :style="{padding: 0}"
                                          :classes="'tool-item hover-bg-green'">
                            <template v-slot:slotTrigger>

                              <title-description-chart
                                    :classes="'small-pie'"
                                    :store="'google-play'"
                                    width="18px"
                                    height="18px"
                                    :donut-data="{
                              title_appearance: inAsoLevel(item.inAso, 'title'),
                              short_description_appearance: inAsoLevel(item.inAso, 'sub_title'),
                              description_appearance: inAsoLevel(item.inAso, 'keywords')}"/>
                            </template>
                            <template v-slot:slotContent>

                              <div class="tooltip-chart-block">
                                <title-description-chart width="80px"
                                                         height="80px"
                                                         :store="'google-play'"
                                                         :show-numbers="true"
                                                         :donut-data="{title_appearance: inAsoLevel(item.inAso, 'title'),
                              short_description_appearance: inAsoLevel(item.inAso, 'sub_title'),
                              description_appearance: inAsoLevel(item.inAso, 'keywords')}"
                                />

                                <div class="text-list">
                                  <ul>
                                    <li :class="[inAsoLevel(item.inAso, 'title')]">
                                      <span><span class="number">1.</span> Title</span>
                                    </li>

                                    <li :class="[inAsoLevel(item.inAso, 'sub_title')]">
                                      <span><span class="number">2.</span> Subtitle</span>
                                    </li>

                                    <li :class="[inAsoLevel(item.inAso, 'keywords')]">
                                      <span><span class="number">3.</span> Keywords</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div class="tooltip-in-aso-detailed"
                                   v-if="item?.inAso">
                                <div class="tooltip-in-aso-detailed-inner">
                                  <div class="in-aso-detail-item"
                                       v-for="tkdItem in item.inAso">

                                    <div class="locale-name">{{ tkdItem.locale }}</div>
                                    <div class="inclusion-block">
                                      <div class="inclusion-item"
                                           :class="tkdItem.title.level">
                                        <div class="item-details"
                                             v-if="tkdItem.title?.missing">
                                          Title (Missing: <span class="red-text">
                                    {{ tkdItem.title?.missing }}
                                    </span>)
                                        </div>
                                        <span class="item-details"
                                              v-else>Title
                                    </span>
                                      </div>
                                      <div class="inclusion-item"
                                           :class="tkdItem.sub_title.level">
                                        <div class="item-details"
                                             v-if="tkdItem.sub_title?.missing">
                                          Subtitle (Missing: <span class="red-text">
                                   {{ tkdItem.sub_title?.missing }}
                                    </span>)
                                        </div>
                                        <span class="item-details"
                                              v-else>Subtitle
                                    </span>
                                      </div>

                                      <div class="inclusion-item"
                                           :class="tkdItem.keywords.level">
                                        <div class="item-details"
                                             v-if="tkdItem.keywords?.missing">
                                          Keywords (Missing: <span class="red-text">
                                   {{ tkdItem.keywords?.missing }}
                                    </span>)
                                        </div>
                                        <span class="item-details"
                                              v-else>Keywords
                                    </span>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>

                            </template>
                          </custom-tooltip>

                        </div>

                      </div>

                      <template v-else-if="headerItem?.value === 'popularity'">
                        {{ tdItem }}
                        <custom-tooltip :classes="'tool-item chart-icon-block'"
                                        :style="{minWidth: '100px'}"
                                        @click="popularityChartClicked(item)">
                          <template v-slot:slotTrigger>
                            <svg-icon icon="chart-icon"
                                      class="icons-margin chart-icon"/>
                          </template>
                          <template v-slot:slotContent>
                            Open Chart
                          </template>
                        </custom-tooltip>
                      </template>

                      <template v-else-if="headerItem?.value === 'daily_impression'">
                        {{ tdItem }}
                      </template>

                      <template v-else-if="headerItem?.value === 'total_keywords'">
                        {{ tdItem }}
                      </template>

                      <template v-else-if="headerItem?.value === 'latest_rank'">
                        <div class="ranking-wrap">
                          <div class="chart-block"
                               style="width: 70px; height: 40px;overflow: hidden;">
                            <vue-highcharts
                                  type="chart"
                                  :options="getRankingData(item.id)"
                                  :redrawOnUpdate="true"
                                  :oneToOneUpdate="false"
                                  :animateOnUpdate="false"/>
                          </div>

                          <div class="rank-value">
                            <app-rank-tool :item="item?.latest_rank"/>
                          </div>
                        </div>
                      </template>

                      <template v-else-if="headerItem?.value === 'results'">
                        {{ tdItem }}
                      </template>
                    </template>
                  </template>
                </template>

              </div>

            </td>
          </template>
        </data-table>
      </div>

      <div class="empty-block"
           v-else>
        No Data
      </div>
    </template>
    <template v-else>
      <div class="preloader-wrap">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
    </template>


    <custom-modal :opened="showLiveSearchModal"
                  :max-width="660"
                  :immutable-width="true"
                  class-name="live-search-modal"
                  @closeModal="closeModal('showLiveSearchModal')">
      <live-search-modal :current-country-code="currentCountryCode"
                         :current-country-name="currentCountryName"
                         :current-country-id="currentCountryId"
                         :current-keyword-item="currentKeywordItem"/>
    </custom-modal>

    <custom-modal :opened="showTableChartModal"
                  :max-width="728"
                  class-name="popularity-chart-modal"
                  @closeModal="closeModal('showTableChartModal')">
      <sap-chart-modal :current-country-code="currentCountryCode"
                       :current-country-name="currentCountryName"
                       :current-keyword-item="currentKeywordItem"/>
    </custom-modal>

    <custom-modal :opened="showAutoSuggestModal"
                  :max-width="492"
                  class-name="add-autosuggest-modal"
                  @closeModal="closeModal('showAutoSuggestModal')">
      <autosuggests-modal :current-country-code="currentCountry?.code"
                          :current-country-name="currentCountry?.name"
                          :current-keyword-item="currentKeywordItem"
                          :current-app="currentApp"
                          @keywords-added="keywordsAdded"
                          @keyword-removed="keywordRemoved"
                          @closeModal="closeModal('showAutoSuggestModal')"/>
    </custom-modal>

    <custom-modal :opened="showDeleteKeywordsModal"
                  :max-width="492"
                  @closeModal="closeModal('showDeleteKeywordsModal')">
      <div class="modal-message">Do you really want to delete<br>
        {{ deleteMultipleKeywordsClicked ? 'these keywords' : 'this keyword' }}?
      </div>
      <template v-slot:footer-action>
        <div class="btn-block action-btns">
          <base-button @click="deleteKeyword">Yes, Delete</base-button>
          <base-button mode="outline"
                       height="40px"
                       width="142px"
                       @click="closeModal('showDeleteKeywordsModal')">Cancel
          </base-button>
        </div>
      </template>
    </custom-modal>
  </div>
</template>

<script>
import WordsTable from "@/components/UI/SharedTables/WordsTable/index.vue";
import DataTable from "@/components/DataTables/DataTable/index.vue";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import {wordsTableConfig} from "@/configs/page-tables-configs/keywords-table";
import {AppMetaKeywordsObject} from "@/utils/factories";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import CustomRangeInput from "@/components/Forms/CustomRangeInput/index.vue";
import FiltersDropdown from "@/components/Dropdowns/FiltersDropdown/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import CustomRadioInput from "@/views/SearchTerms/RecommendedKeywords/components/CustomRadioInput/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import TitleDescriptionDonut from "@/components/UI/TitleDescriptionDonut/index.vue";
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import LiveSearchModal from "@/components/Modals/LiveSearchModal/index.vue";
import CustomChips from "@/components/Forms/CustomChips/index.vue";
import {formatDate, getCookie} from "@/utils/utils";
import {keywordsRankingChartOptions} from "@/configs/keyword-tracking-ranking-chart/keyword-meta-editor-chart-config";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";
import TranslateOneTool from "../../../../components/DataTables/Tools/TranslateOneTool/index.vue";
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import AutosuggestsModal from "@/components/Modals/AutosuggestsModal/index.vue";

export default {
  name: "Keywords",
  components: {
    AppRankTool,
    AutosuggestsModal,
    TranslateOneTool,
    TranslateTool,
    SvgIcon,
    WordsTable,
    DataTable,
    'basic-search-input': BasicSearchInput,
    'data-table-checkbox': TableCheckbox,
    'range-input': CustomRangeInput,
    'radio-input': CustomRadioInput,
    'filters-dropdown': FiltersDropdown,
    'custom-dropdown': CustomDropdown,
    'title-description-chart': TitleDescriptionDonut,
    'sap-chart-modal': SapChartModal,
    'custom-chips': CustomChips,
    LiveSearchModal,
  },
  emits: ['save-table-words', 'keyword-deleted', 'scroll-to-anchor'],
  props: {
    countryCode: {
      type: String
    },
    currentCountryCode: {
      type: String
    },
    currentCountryName: {
      type: String
    },
    currentCountryId: {
      type: Number
    },
    currentRedactionId: {
      type: String
    },
    currentFilterReset: {
      type: Number,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    searchKeywordValue: {
      type: String,
      default: null,
    },
    tableHeaders: {
      type: Object,
      default: null,
    },
    tableOptions: {
      type: Object,
      default: () => {
        return {
          noPagination: false,
        }
      }
    },
    editableMetas: {
      type: Object,
    }
  },
  data() {
    return {
      translateIsActive: false,
      headers: wordsTableConfig.headers,
      filtersReset: true,
      keywordsLoaded: false,
      filtersLoaded: false,
      showKeywordsLoader: false,
      wordsTableData: {},
      tablePagination: {
        current_page: 1,
        from: 1,
        to: 20,
        per_page: 50,
        last_page: 1,
        total: null,
        links: [
          {
            active: false,
            label: "&laquo; Previous",
          },
          {
            active: true,
            label: "1",
          },
          {
            active: false,
            label: "Next &raquo;",
          }
        ],
        skip: 0,
      },
      searchKeywordsInput: '',
      rankingPresetsData: {
        'Top 3': {
          min: 1,
          max: 3
        },
        'Top 5': {
          min: 1,
          max: 5
        },
        'Top 10': {
          min: 1,
          max: 10
        },
        'Top 30': {
          min: 1,
          max: 30
        },
        'Top 50': {
          min: 1,
          max: 50
        }
      },
      maxImpressions: 100,
      closeFiltersTrigger: 0,
      tableFilters: {
        popularity: {
          text: 'Popularity',
          value: 'range_sap',
          isActive: false,
          opened: false,
        },
        dailyImpressions: {
          text: 'Daily Impression',
          value: 'range_impression',
          isActive: false,
          opened: false,
        },
        rank: {
          text: 'Rank',
          value: 'range_rank',
          isActive: false,
          opened: false,
        },
      },
      filtersObject: {},
      filtersInitObject: {},
      filtersDefaultObject: {
        filter: {},
        country_code: null,
        sap: {
          min: null,
          max: null,
          filter: null,
        },
        impressions: {
          min: null,
          max: null
        },
        rank: {
          min: null,
          max: null
        },
      },
      filtersCollapsedCounter: 0,
      closeAllDropdownsFlag: 0,
      keywordsCheckedArray: [],
      showCopyActiveFlag: false,
      showDeclinedActiveFlag: false,
      checkAllChanged: false,
      showDeleteKeywordsModal: false,
      showLiveSearchModal: false,
      showTableChartModal: false,
      showAutoSuggestModal: false,
      addedKeywordsArray: [],
      liveSearchModalExtended: true,
      modalDataLoader: false,
    }
  },
  methods: {
    async translateClickHandler() {
      if (this.translateIsActive) {
        this.wordsTableData.forEach((item, index) => {
          item.translate = null;
        });
        this.translateIsActive = false;
        return;
      }
      let translatesByKeyword = await translates.translateKeywords(this, this.currentApp?.store?.key, this.paginatedTrackedKeywords.map(item => item.keyword));
      this.wordsTableData.forEach((item, index) => {
        item.translate = translatesByKeyword[item.keyword] ?? null;
      });
      this.translateIsActive = true;
    },
    setDefaultTableData() {
      this.$store.dispatch('metaData/SET_SORTING_META', {
        sort_by: 'popularity',
        order: 'desc',
        country_code: this.filtersInitObject?.country_code ?? this.currentKeywordCountryCode,
        'filter[range_rank][from]': null,
        'filter[range_rank][to]': null,
        'filter[range_impression][from]': null,
        'filter[range_impression][to]': null,
        'filter[range_sap][to]': null,
        'filter[range_sap][from]': null,
        'filter[like_keyword][query]': null,
        'filter[with_word][query]': null,
      });
      this.filtersInitObject = {...this.filtersDefaultObject};
      this.$store.dispatch('metaData/CONSTRUCT_URL_QUERY_STRING', null);
    },
    async fetchWordsTableData(searchQuery) {
      this.keywordsLoaded = false;
      const countryCode = this.countryCode;

      let url = `?app_id=${this.currentApp?.id}&country_code=${countryCode}`;

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined') {
        url += `&redaction_id=${this.currentRedactionId}`;
      }
      if (searchQuery) {
        url += '&filter[with_word][query]=' + searchQuery;
      }

      if (this.urlQueryString === '') {
        url += '&sort_by=popularity&order=desc';
      } else {
        url += this.urlQueryString;
      }

      const wordsData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_KEYWORDS + url);

      this.wordsTableData = AppMetaKeywordsObject(wordsData?.list, this.countryList);

      this.filtersObject = {
        min_rank: wordsData?.filter_value_limits?.min_rank ?? 0,
        max_rank: wordsData?.filter_value_limits?.max_rank ?? 100,
        min_daily_impression: wordsData?.filter_value_limits?.min_daily_impression ?? 0,
        max_daily_impression: wordsData?.filter_value_limits?.max_daily_impression ?? 0,
        min_popularity: wordsData?.filter_value_limits?.min_popularity ?? 0,
        max_popularity: wordsData?.filter_value_limits?.max_popularity ?? 0,
        min_results: wordsData?.filter_value_limits?.min_results ?? 0,
        max_results: wordsData?.filter_value_limits?.max_results ?? 0,
      };

      // this.$store.dispatch('SET_PAGINATION_META', wordsData);
      this.updatePagination({total: wordsData?.list?.length});

      this.keywordsCheckedArray = this.wordsTableData.map(keyword => {
        return {
          id: keyword.id,
          checked: false,
          keyword: keyword?.keyword,
        }
      });

      this.keywordsLoaded = true;
    },
    async fetchCountryCatalog() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_LOCALES;
      const result = await httpRequest('GET', url);
      this.$store.dispatch('SET_COUNTRY_LIST', result);
    },
    async filterApplied(e, filter) {
      const keyMin = 'filter[' + [filter] + '][from]';
      const keyMax = 'filter[' + [filter] + '][to]';
      let filterObject = {
        [keyMin]: e?.min ?? null,
        [keyMax]: e?.max ?? null,
      };

      this.cleanUnusedFilters();
      this.updatePagination({page: 1});
      await this.updateSort(filterObject);
    },
    async updateSort(data, mode) {
      let innerData = {...data};

      if (data?.page || data?.per_page) {
        this.updatePagination(data);
        this.$emit('scroll-to-anchor');
      } else {

        if (this.searchKeywordValue) {
          innerData = {
            ...innerData,
            'filter[with_word][query]': this.searchKeywordValue
          }
        }

        this.$store.dispatch('metaData/SET_SORTING_META', innerData);
        this.$store.dispatch('metaData/CONSTRUCT_URL_QUERY_STRING', innerData);
        if (mode) {
          await this.fetchWordsTableData(mode);
        } else {
          await this.fetchWordsTableData(this.searchKeywordValue ?? null);
        }
        this.closeAllDropdownsFlag++;
      }

      this.$emit('scroll-to-anchor');
    },
    async searchKeywordsHandler(inputValue) {
      this.tableIsLoading = true;
      this.showKeywordsLoader = true;
      this.searchKeywordsInput = inputValue;

      this.resetTablePagination();

      this.$store.dispatch('metaData/SET_SORTING_META', {'filter[like_keyword][query]': inputValue});
      this.$store.dispatch('metaData/CONSTRUCT_URL_QUERY_STRING', {'filter[like_keyword][query]': inputValue});
      await this.fetchWordsTableData(null, inputValue);
      this.$emit('scroll-to-anchor');
      setTimeout(() => {
        this.showKeywordsLoader = false;
        this.tableIsLoading = false;
      }, 300);
    },
    async clearKeywordsSearchInput() {
      await this.searchKeywordsHandler('');
    },
    async saveTableWords(wordsToUse) {
      this.$emit('save-table-words', wordsToUse);
    },
    async popularityChartClicked(item) {
      this.setCurrentKeywordItem(item?.keyword, 'showTableChartModal');
    },
    async filterChecked(item) {
      let filterItemKey;
      const currentFilters = {...this.tableFilters};
      for (const key in currentFilters) {
        if (currentFilters[key]?.text === item.text) {
          filterItemKey = key;
          break;
        }
      }
      currentFilters[filterItemKey].isActive = !currentFilters[filterItemKey].isActive;

      if (currentFilters[filterItemKey].isActive === false) {
        const value = item.value !== 'keyword_tracked' ? null : {title: 'All'};
        await this.filterApplied(value, item.value);
      }

      // this.tableFilters = {...currentFilters};
      this.closeFiltersTrigger++;
      // let localTableFilters = {...this.tableFilters};
      let localTableFilters = {...currentFilters};

      for (const key in localTableFilters) {
        if (localTableFilters[key].value === item.value) {
          localTableFilters[key].opened = true;
        }
      }

      // this.cleanUnusedFilters();
      this.tableFilters = {...localTableFilters};
    },
    autoSuggestToolClicked(item) {
      this.currentKeywordDelete = item?.id;
      this.setCurrentKeywordItem(item?.keyword, 'showAutoSuggestModal');
    },
    async keywordsAdded(list) {
      await this.fetchWordsTableData();
      await this.fetchDataCounts();
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
    },
    keywordRemoved(id, term) {

    },
    async deleteKeyword() {
      this.fetchMode = 'delete';
      if (this.deleteMultipleKeywordsClicked) {
        const keywordsId = this.keywordsTrackingChecked.map(keyword => {
          if (keyword.checked) {
            return +keyword.id;
          }
        });

        const data = {
          application_id: +getCookie('currentAppId'),
          keywords: keywordsId,
          country_code: this.currentCountry?.code
        };

        if (this.currentRedactionId && this.currentRedactionId !== 'undefined') {
          data.redaction_id = this.currentRedactionId;
        }

        let result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.DELETE_KEYWORDS, data);
      } else {
        const data = {
          application_id: +getCookie('currentAppId'),
          country_code: this.currentCountry?.code,
          keywords: [this.currentKeywordDelete]
        };

        if (this.currentRedactionId && this.currentRedactionId !== 'undefined') {
          data.redaction_id = this.currentRedactionId;
        }

        let result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.DELETE_KEYWORDS, data);
      }
      this.closeModal('showDeleteKeywordsModal');
      this.keywordsLoaded = false;

      if (this.deleteMultipleKeywordsClicked) {
        this.$emit('keyword-deleted', {action: 'close-panel'});
      } else {
        await this.fetchWordsTableData(this.searchKeywordValue ?? null);
      }

      this.keywordsLoaded = true;
      this.$store.dispatch('UPDATE_USER_LIMITS');
      this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
    },
    async keywordsMultiDelete() {
      this.openModal('showDeleteKeywordsModal');
      this.deleteMultipleKeywordsClicked = true;
      this.showDeclinedActiveFlag = true;

      setTimeout(() => {
        this.showDeclinedActiveFlag = false;
      }, 3000);
    },
    async liveSearchToolClicked(item) {
      this.setCurrentKeywordItem(item?.keyword, 'showLiveSearchModal');
    },
    async saveAddedKeywordsClicked() {
      this.keywordsLoaded = false;
      const searchKeywordsInput = this.searchKeywordsInput;

      if (this.keywordsChipsInput.length > 0) {
        if (!this.addedKeywordsArray.some(e => e.name === this.keywordsChipsInput)) {
          this.addedKeywordsArray.push({
            name: this.keywordsChipsInput,
            selected: true
          });
        }
      }

      this.closeModal('showAddKeywordsModal');
      await this.saveAddedKeywords('addedKeywordsArray');
      this.addedKeywordsArray = [];
      this.keywordsChipsInput = '';
      this.keywordsLoaded = true;

      this.searchKeywordsInput = searchKeywordsInput;
    },
    async saveAddedKeywords(keywordsArray, mode = 'string') {
      //send AJAX to save changes
      let preparedArray = mode === 'string' ? this[keywordsArray] : keywordsArray;
      const currentAppId = getCookie('currentAppId');

      this.fetchMode = 'add';

      if (currentAppId && preparedArray.length > 0) {
        let formData = new FormData();
        formData.append("country_code", this.currentCountryCode);
        formData.append("application_id", currentAppId);

        preparedArray.forEach((keyword, idx) => {
          formData.append(`keywords[${idx}]`, keyword.name);
        });

        let result = await httpRequest('POST', process.env.VUE_APP_API_URL + this.$ApiUrls.keywordsTracking.ALL_KEYWORDS, formData);
        // this.$store.dispatch('keywordsTracking/SET_SORTING_META', null);
        // await this.fetchTableData();
        await this.fetchWordsTableData(this.searchKeywordValue ?? null);
        // await this.fetchDataCounts();
        preparedArray = [];
        this.$store.dispatch('UPDATE_USER_LIMITS');
        this.$store.dispatch('keywordsTracking/SET_TRACKING_KEYWORDS_COUNT');
      }
    },
    updatePagination(data) {
      this.translateIsActive = false;

      const tablePagination = {...this.tablePagination};
      for (const key in data) {
        if (key === 'page') {
          tablePagination.current_page = data[key];
        } else if (key === 'per_page') {
          tablePagination[key] = data[key];
          tablePagination.current_page = 1;
        } else {
          tablePagination[key] = data[key];
        }
      }

      const from = (tablePagination?.per_page * +tablePagination?.current_page) - +tablePagination?.per_page;
      const to = +tablePagination?.current_page * +tablePagination?.per_page;
      tablePagination.from = from <= 0 ? 1 : from;
      tablePagination.to = to > tablePagination.total ? tablePagination.total : to;
      tablePagination.current_page = +tablePagination.current_page;

      let linksCount = +tablePagination.total / +tablePagination?.per_page;
      linksCount = Math?.ceil(linksCount);
      tablePagination.last_page = linksCount;

      const maxValue = 6;
      let links = [];

      if (tablePagination?.current_page <= 3 && linksCount >= maxValue) {
        for (let i = 1; i <= maxValue; i++) {
          links.push(
              {
                active: i === tablePagination?.current_page,
                label: i + '',
              }
          );
        }
      } else {
        links = [
          {
            active: true,
            label: tablePagination?.current_page + '',
          },
        ];

        if (tablePagination?.current_page - 1 > 0) {
          links.unshift({
            active: false,
            label: tablePagination?.current_page - 1 + '',
          });
        }

        if (tablePagination?.current_page - 2 > 0) {
          links.unshift({
            active: false,
            label: tablePagination?.current_page - 2 + '',
          });
        }

        if (tablePagination?.current_page + 1 <= tablePagination.last_page) {
          links.push({
            active: false,
            label: tablePagination?.current_page + 1 + '',
          });
        }

        if (tablePagination?.current_page + 2 <= tablePagination.last_page) {
          links.push({
            active: false,
            label: tablePagination?.current_page + 2 + '',
          });
        }
      }

      tablePagination.links = [...links];

      let leftArrowLink = {
        active: false,
        label: "&laquo; Previous",
      };

      let rightArrowLink = {
        active: false,
        label: "Next &raquo;",
      };

      tablePagination.links.unshift(leftArrowLink);
      tablePagination.links.push(rightArrowLink);

      this.tablePagination = {...tablePagination};
    },
    cleanUnusedFilters() {
      let localTableFilters = {...this.tableFilters};

      for (const key in localTableFilters) {
        if (localTableFilters[key].opened) {

          if (localTableFilters[key].value === "range_sap" && !this.filtersInitObject['filter[range_sap][from]']) {
            localTableFilters[key].opened = false;
          }

          if (localTableFilters[key].value === "range_impression" && !this.filtersInitObject['filter[range_impression][from]']) {
            localTableFilters[key].opened = false;
          }

          if (localTableFilters[key].value === "range_rank" && !this.filtersInitObject['filter[range_rank][from]']) {
            localTableFilters[key].opened = false;
          }

        }
      }

      this.tableFilters = {...localTableFilters};
    },
    detectTextOverflow(string) {
      if (!string?.length) {
        return;
      }

      let maxWidth;

      if (window.innerWidth > 1480) {
        maxWidth = window.innerWidth - 960;
        // maxWidth = '100%';
      } else if (window.innerWidth < 1481) {
        maxWidth = 270;
      }

      const defaultCharWidth = 10;
      const stringLength = defaultCharWidth * string?.length;

      return stringLength > maxWidth;
    },
    inAsoLevel(array, type) {
      const fullResult = array.some(item => {
        return item?.[type]?.level === 'full';
      });

      const partlyResult = array.some(item => {
        return item?.[type]?.level === 'partial';
      });

      if (fullResult) {
        return 'green';
      } else if (!fullResult && partlyResult) {
        return 'yellow';
      } else {
        return 'grey';
      }
    },
    setCurrentKeywordItem(item, modalName) {
      this.currentKeywordItem = item;
      this.openModal(modalName);
    },
    getKeywordCheckedValue(id) {
      return this.keywordsCheckedArray.find(item => item.id === id)?.checked;
    },
    checkAllClicked(allChecked) {
      let checkedArray = this.paginatedTrackedKeywords.map(item => {
        return {
          ...item,
          checked: allChecked
        }
      });

      this.keywordsCheckedArray = [...checkedArray];
      this.checkAllChanged = allChecked;
    },
    keywordsTableInputChecked(checked, item) {
      this.showCopyActiveFlag = false;

      const checkedArray = this.keywordsCheckedArray.map(keyword => {
        return {
          id: keyword.id,
          checked: keyword.id === item.id ? checked : keyword.checked,
          keyword: keyword?.keyword,
        };
      });
      this.keywordsCheckedArray = [...checkedArray];
      if (!checked) {
        this.checkAllChanged = checked;
      }
    },
    selectAllKeywords() {
      let checkedArray = this.wordsTableData.map(item => {
        return {
          ...item,
          checked: true
        }
      });

      this.keywordsCheckedArray = [...checkedArray];
      this.checkAllChanged = true;
    },
    unSelectAllKeywords() {
      let checkedArray = this.wordsTableData.map(item => {
        return {
          ...item,
          checked: false
        }
      });

      this.keywordsCheckedArray = [...checkedArray];
      this.checkAllChanged = false;
    },
    multiCopy() {
      let textAreaContent = '';
      this.keywordsTrackingChecked.forEach(item => {
        textAreaContent += `${item?.keyword}\n`;
      });

      const tempText = document.createElement("textarea");
      tempText.value = textAreaContent;
      document.body.appendChild(tempText);
      tempText.select();

      document.execCommand("copy");
      document.body.removeChild(tempText);

      this.showCopyActiveFlag = true;

      setTimeout(() => {
        this.showCopyActiveFlag = false;
      }, 1500);
    },
    arrayHasSelectedItems(array) {
      return this[array]?.filter(item => {
        if (item?.selected && !item?.frozen) {
          return item;
        }
      }).length > 0;
    },

    keywordDeleteButtonClicked(item) {
      this.openModal('showDeleteKeywordsModal');
      this.currentKeywordDelete = item?.id;
      this.deleteMultipleKeywordsClicked = false;
    },
    getRankingData(id) {
      const currData = this.paginatedTrackedKeywords.filter(keyword => keyword.id === id);
      const rankingData = currData[0]?.rank_history?.sort(function (a, b) {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return dateA < dateB ? -1 : 1;
      });

      const dates = rankingData.map(item => {
        return formatDate(item?.created_at, 'month-day-year');
      });

      const rank = rankingData.map(item => {
        return item?.rank;
      });

      let self = this;

      return {
        ...keywordsRankingChartOptions,
        xAxis: {
          categories: dates
        },
        series: [{
          data: rank,
          lineWidth: 1.2,
          marker: {
            states: {
              hover: {
                fillColor: 'white',
                radius: 3,
                lineWidth: 1,
                lineColor: '#7688ff',
              }
            }
          }
        }],
        tooltip: {
          width: 100,
          distance: 20,
          padding: 8,
          outside: true,
          useHTML: true,
          backgroundColor: '#fff',
          borderColor: '#d7dde7',
          borderRadius: 4,
          shadow: false,
          shape: "square",
          hideDelay: 15,
          formatter: function () {
            let rankDifference = null;
            const category = this.point.category;

            for (const key in dates) {
              if (category === formatDate(dates[key], 'month-day-year')) {
                rankDifference = rankingData[key]?.difference;
                break;
              }
            }

            const differenceColor = rankDifference > 0 ? 'green' : 'red';
            const differenceRenderValue = rankDifference > 0 ? '+' + rankDifference : rankDifference;
            let differenceBlock = '';

            if (rankDifference !== 0 && rankDifference !== null && rankDifference !== undefined) {
              differenceBlock = `<span class="difference ${differenceColor}">${differenceRenderValue}</span>`;
            }

            return `<div class="tooltip-inner-container">${this.point.category}
                      <br />
                      <span class="rank">Rank: <strong>${this.point.y}</strong>
                      ${differenceBlock}
                      </span>
                    </div>`
          }
        },
      };
    },
    resetTablePagination() {
      this.tablePagination.size = 50;
      this.tablePagination.per_page = 50;
      this.tablePagination.skip = 0;
      this.tablePagination.current_page = 1;
    },
  },
  async mounted() {
    if (this.tableHeaders) {
      this.headers = [...this.tableHeaders?.headers];
    }
    this.setDefaultTableData();
    await this.fetchCountryCatalog();
    await this.fetchWordsTableData(this.searchKeywordValue);
    this.filtersLoaded = true;
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'userData',
      'sidebarIsCollapsed',
      'currentCountry',
      'currentCountryManual',
      'isTablet',
      'isMobile',
      'countryList'
    ]),
    ...mapGetters('metaData', [
      'sortingMeta',
      'urlQueryString',
    ]),
    paginatedTrackedKeywords() {
      if (this.wordsTableData) {
        const splicedResult = [...this.wordsTableData];
        const from = this.tablePagination.from === 1 ? this.tablePagination.from - 1 : this.tablePagination.from;
        return splicedResult.slice(from, this.tableOptions?.per_page ?? this.tablePagination.to);
      }

      return this.wordsTableData;
    },
    keywordsTrackingChecked() {
      return this.keywordsCheckedArray.filter(keyword => keyword.checked);
    },
  },
  watch: {
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.setDefaultTableData();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.setDefaultTableData();
      }
    },
    currentFilterReset(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setDefaultTableData();
      }
    },
    async searchKeywordValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.dispatch('metaData/SET_SORTING_META', {'filter[like_keyword][query]': this.searchKeywordValue});
        this.$store.dispatch('metaData/CONSTRUCT_URL_QUERY_STRING', {'filter[like_keyword][query]': this.searchKeywordValue});

        await this.fetchWordsTableData(this.searchKeywordValue);
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
<style scoped>
.filters-trigger {
  padding-right: 0;
}
.translate-block {
  margin-right: 10px;
}
.block-content {
  align-items: flex-start !important;
  flex-direction: column !important;
}
</style>