<template>
  <div class="table-wrap">
    <div class="search-and-filter-block"
         v-show="filtersLoaded">

      <div class="filters-options-block">
        <div class="filters-block">
          <div class="filter-item-wrap filters-trigger">
            <custom-dropdown :min-width="'92px'"
                             :with-icon="false"
                             :no-border="true"
                             :with-shadow="true"
                             :dropdown-width="'295px'"
                             :close-dropdown="closeFiltersTrigger"
                             :classes="'filters-btn'">
              <template v-slot:header>
                Filters
              </template>
              <template v-slot:content>
                <div class="select-options">
                  <div class="option"
                       v-for="(item, idx) in tableFilters"
                       :key="idx">
                    <svg-icon icon="plus"
                              class="filter-marker"
                              :class="{active: item.isActive}"/>
                    <data-table-checkbox :checked="item.isActive"
                                         @input-checked="filterChecked(item)">
                      {{ item.text }}
                    </data-table-checkbox>
                  </div>
                </div>
              </template>
            </custom-dropdown>
          </div>

          <translate-tool :active="translateIsActive" @translate-clicked="translateClickHandler"/>

          <div class="filter-item-wrap popularity-filter"
               v-if="tableFilters.popularity.isActive">
            <range-input :min-width="'168px'"
                         :no-presets="true"
                         :close-dropdown="closeAllDropdownsFlag"
                         @filter-applied="filterApplied($event, 'range_sap')"
                         :min-value="filtersObject.min_sap"
                         :max-value="filtersObject.max_sap"
                         :initial-data="filtersInitObject?.sap ?? null"
                         :opened-from-outside="tableFilters.popularity.opened">Total Popularity
            </range-input>
          </div>

          <div class="filter-item-wrap impressions-filter"
               v-if="tableFilters.dailyImpressions.isActive">
            <range-input :min-width="'168px'"
                         :no-presets="true"
                         :close-dropdown="closeAllDropdownsFlag"
                         custom-max-width="80px"
                         @filter-applied="filterApplied($event, 'range_impression')"
                         :min-value="filtersObject.min_daily_impression"
                         :max-value="filtersObject.max_daily_impression"
                         :initial-data="filtersInitObject?.impressions ?? null"
                         :opened-from-outside="tableFilters.dailyImpressions.opened">Total Daily Impr.
            </range-input>
          </div>

          <div class="filter-item-wrap character-filter"
               v-if="tableFilters.characterCount.isActive">
            <range-input :min-width="'168px'"
                         :no-presets="true"
                         :close-dropdown="closeAllDropdownsFlag"
                         custom-max-width="80px"
                         @filter-applied="filterApplied($event, 'range_character_count')"
                         :min-value="filtersObject.min_character_count"
                         :max-value="filtersObject.max_character_count"
                         :initial-data="filtersInitObject?.character_count ?? null"
                         :opened-from-outside="tableFilters.characterCount.opened">Character Count
            </range-input>
          </div>

          <div class="filter-item-wrap keywords-filter"
               v-if="tableFilters.keywordsCount.isActive">
            <range-input :min-width="'168px'"
                         :no-presets="true"
                         :close-dropdown="closeAllDropdownsFlag"
                         custom-max-width="80px"
                         @filter-applied="filterApplied($event, 'range_keywords_count')"
                         :min-value="filtersObject.min_keywords_count"
                         :max-value="filtersObject.max_keywords_count"
                         :initial-data="filtersInitObject?.keywords_count ?? null"
                         :opened-from-outside="tableFilters.keywordsCount.opened">Total Keywords
            </range-input>
          </div>

        </div>

        <div class="search-keywords-block">
          <basic-search-input placeholder="Search by words"
                              :hide-results-block="true"
                              @search-input-changed="searchKeywordsHandler"
                              :clear-input="searchKeywordsInput">

            <template v-slot:search-icon>
              <template v-if="!showKeywordsLoader">
                <svg-icon v-if="searchKeywordsInput?.length === 0"
                          class="search-icon"
                          icon="search-solid"/>

                <svg-icon v-else
                          @click="clearKeywordsSearchInput"
                          class="close-icon"
                          icon="close"/>
              </template>
            </template>
          </basic-search-input>
        </div>
      </div>

    </div>

    <template v-if="keywordsLoaded">
      <div class="words-table"
           v-if="wordsTableData?.length > 0">
        <words-table :headers="headers"
                     :table-data="wordsTableData"
                     :table-pagination="tablePagination"
                     :sorting-meta="sortingMeta"
                     :current-country-code="currentCountryCode"
                     :current-country-name="currentCountryName"
                     :countries-list="countriesList"
                     ref="wordsTable"
                     @save-table-words="saveTableWords"
                     @update-sort="updateSort"></words-table>
      </div>

      <div class="empty-block"
           v-else>
        No Data
      </div>
    </template>
    <template v-else>
      <div class="preloader-wrap">
        <preloader-table-small loader-size="50px"></preloader-table-small>
      </div>
    </template>

  </div>
</template>

<script>
import WordsTable from "@/components/UI/SharedTables/WordsTable/index.vue";
import {httpRequest} from "@/api";
import {mapGetters} from "vuex";
import {wordsTableConfig} from "@/configs/page-tables-configs/words-table";
import {AppMetaWordsObject} from "@/utils/factories";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import CustomRangeInput from "@/components/Forms/CustomRangeInput/index.vue";
import FiltersDropdown from "@/components/Dropdowns/FiltersDropdown/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import CustomRadioInput from "@/views/SearchTerms/RecommendedKeywords/components/CustomRadioInput/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import translates from "@/api/modules/translates";

export default {
  name: "Words",
  components: {
    TranslateTool,
    WordsTable,
    'basic-search-input': BasicSearchInput,
    'data-table-checkbox': TableCheckbox,
    'range-input': CustomRangeInput,
    'radio-input': CustomRadioInput,
    'filters-dropdown': FiltersDropdown,
    'custom-dropdown': CustomDropdown,
  },
  emits: ['save-table-words', 'scroll-to-anchor'],
  props: {
    countryCode: {
      type: String
    },
    currentCountryCode: {
      type: String
    },
    currentCountryName: {
      type: String
    },
    currentRedactionId: {
      type: String
    },
    currentFilterReset: {
      type: Number,
    },
    countriesList: {
      type: Array,
    },
    editableMetas: {
      type: Object,
    }
  },
  data() {
    return {
      translateIsActive: false,
      headers: wordsTableConfig.headers,
      filtersReset: true,
      keywordsLoaded: false,
      filtersLoaded: false,
      showKeywordsLoader: false,
      wordsTableData: {},
      tablePagination: {
        current_page: 1,
        from: 1,
        to: 20,
        per_page: 50,
        last_page: 1,
        total: null,
        links: [
          {
            active: false,
            label: "&laquo; Previous",
          },
          {
            active: true,
            label: "1",
          },
          {
            active: false,
            label: "Next &raquo;",
          }
        ],
        skip: 0,
        size: 50,
      },
      searchKeywordsInput: '',
      maxImpressions: 100,
      closeFiltersTrigger: 0,
      tableFilters: {
        popularity: {
          text: 'Total Popularity',
          value: 'range_sap',
          isActive: false,
          opened: false,
        },
        dailyImpressions: {
          text: 'Total Daily Impressions',
          value: 'range_impression',
          isActive: false,
          opened: false,
        },
        characterCount: {
          text: 'Character Count',
          value: 'range_character_count',
          isActive: false,
          opened: false,
        },
        keywordsCount: {
          text: 'Total Keywords',
          value: 'range_keywords_count',
          isActive: false,
          opened: false,
        },
      },
      filtersObject: {},
      filtersInitObject: {},
      filtersDefaultObject: {
        filter: {},
        country_code: null,
        sap: {
          min: null,
          max: null,
          filter: null,
        },
        impressions: {
          min: null,
          max: null
        },
        keywords_count: {
          min: null,
          max: null
        },
        keywords_characters: {
          title: null,
          value: null
        },
      },
      filtersCollapsedCounter: 0,
      closeAllDropdownsFlag: 0,
    }
  },
  methods: {
    async translateClickHandler() {
      if (this.translateIsActive) {
        this.wordsTableData.forEach((item, index) => {
          item.translate = null;
        });
        this.translateIsActive = false;
        return;
      }
      let translatesByKeyword = await translates.translateKeywords(this, this.currentApp?.store?.key, this.wordsTableData.map(item => item.word));
      this.wordsTableData.forEach((item, index) => {
        item.translate = translatesByKeyword[item.word] ?? null;
      });
      this.translateIsActive = true;
    },
    setDefaultTableData() {
      this.$store.dispatch('keywordsMetaData/SET_SORTING_META', {
        sort_by: 'popularity',
        order: 'desc',
        country_code: this.filtersInitObject?.country_code ?? this.currentKeywordCountryCode,
        'filter[range_sap][from]': null,
        'filter[range_sap][to]': null,
        'filter[range_impression][from]': null,
        'filter[range_impression][to]': null,
        'filter[range_keywords_count][to]': null,
        'filter[range_keywords_count][from]': null,
        'filter[range_character_count][to]': null,
        'filter[range_character_count][from]': null,
        'filter[like_keyword][query]': null,
      });
      this.filtersInitObject = {...this.filtersDefaultObject};
      this.$store.dispatch('keywordsMetaData/CONSTRUCT_URL_QUERY_STRING', null);
    },
    async fetchWordsTableData(searchQuery) {
      this.keywordsLoaded = false;
      const countryCode = this.countryCode;

      let url = `?app_id=${this.currentApp?.id}&country_code=${countryCode}`;

      if (this.currentRedactionId && this.currentRedactionId !== 'undefined') {
        url += `&redaction_id=${this.currentRedactionId}`;
      }

      url += '&size=' + this.tablePagination?.size + '&skip=' + this.tablePagination?.skip;

      if (searchQuery) {
        url += '&filter[like_keyword]=' + searchQuery;
      }

      if (this.urlQueryString === '') {
        url += '&sort_by=popularity&order=desc';
      } else {
        url += this.urlQueryString;
      }

      const wordsData = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.metaEditor.GET_WORDS + url);

      this.wordsTableData = AppMetaWordsObject(wordsData?.list, this.countryList);

      let pagination = {...this.tablePagination};
      pagination.total = wordsData?.total_words;
      // pagination.per_page = 50;
      this.tablePagination = {...pagination};

      this.filtersObject = {
        min_sap: wordsData?.filter_value_limits?.min_sap ?? 0,
        max_sap: wordsData?.filter_value_limits?.max_sap ?? 100,
        min_daily_impression: wordsData?.filter_value_limits?.min_daily_impression ?? 0,
        max_daily_impression: wordsData?.filter_value_limits?.max_daily_impression ?? 0,
        min_keywords_count: wordsData?.filter_value_limits?.min_keywords_count ?? 0,
        max_keywords_count: wordsData?.filter_value_limits?.max_keywords_count ?? 0,
        min_character_count: wordsData?.filter_value_limits?.min_character_count ?? 0,
        max_character_count: wordsData?.filter_value_limits?.max_character_count ?? 0,
      };

      // this.$store.dispatch('SET_PAGINATION_META', wordsData);
      this.updatePagination({total: wordsData?.total_words});
      this.keywordsLoaded = true;
    },
    async fetchCountryCatalog() {
      let url = process.env.VUE_APP_API_URL + this.$ApiUrls.user.FILTER_LOCALES;
      const result = await httpRequest('GET', url);
      this.$store.dispatch('SET_COUNTRY_LIST', result);
    },
    async filterApplied(e, filter) {
      const keyMin = 'filter[' + [filter] + '][from]';
      const keyMax = 'filter[' + [filter] + '][to]';
      let filterObject = {
        [keyMin]: e?.min ?? null,
        [keyMax]: e?.max ?? null,
      };

      this.cleanUnusedFilters();
      this.updatePagination({page: 1});
      await this.updateSort(filterObject);
    },
    async updateSort(data, mode) {
      this.$emit('scroll-to-anchor');

      if (data?.page || data?.per_page) {
        let perPage = this.tablePagination.per_page ?? data?.per_page;
        let currPage = data.page ?? 0;

        if (data?.per_page) {
          perPage = data.per_page;
        }

        // const page = data?.page === 1 ? data?.page : data?.page - 1;
        const page = currPage === 1 ? currPage : (currPage > 0 ? currPage : 1) - 1;

        this.tablePagination.size = perPage ?? 50;
        this.tablePagination.per_page = perPage ?? 50;
        this.tablePagination.skip = page * perPage;
        this.updatePagination(data);
        await this.fetchWordsTableData();
      } else {
        this.$store.dispatch('keywordsMetaData/SET_SORTING_META', data);
        this.$store.dispatch('keywordsMetaData/CONSTRUCT_URL_QUERY_STRING', data);
        if (mode) {
          await this.fetchWordsTableData(mode);
        } else {
          await this.fetchWordsTableData();
        }
        this.closeAllDropdownsFlag++;
      }

      this.$emit('scroll-to-anchor');
    },
    async searchKeywordsHandler(inputValue) {
      this.tableIsLoading = true;
      this.showKeywordsLoader = true;
      this.searchKeywordsInput = inputValue;

      this.resetTablePagination();

      this.$store.dispatch('keywordsMetaData/SET_SORTING_META', {'filter[like_keyword][query]': inputValue});
      this.$store.dispatch('keywordsMetaData/CONSTRUCT_URL_QUERY_STRING', {'filter[like_keyword][query]': inputValue});
      await this.fetchWordsTableData(null, inputValue);
      this.$emit('scroll-to-anchor');
      setTimeout(() => {
        this.showKeywordsLoader = false;
        this.tableIsLoading = false;
      }, 300);
    },
    async clearKeywordsSearchInput() {
      await this.searchKeywordsHandler('');
    },
    updatePagination(data) {
      this.translateIsActive = false;

      const tablePagination = {...this.tablePagination};
      for (const key in data) {
        if (key === 'page') {
          tablePagination.current_page = data[key];
        } else if (key === 'per_page') {

          // tablePagination[key] = data[key];
          tablePagination.size = data[key];
          tablePagination.per_page = data[key];
          tablePagination.current_page = 1;
        } else {
          tablePagination[key] = data[key];
        }
      }

      const from = (tablePagination?.per_page * +tablePagination?.current_page) - +tablePagination?.per_page;
      const to = +tablePagination?.current_page * +tablePagination?.per_page;
      tablePagination.from = from <= 0 ? 1 : from;
      tablePagination.to = to > tablePagination.total ? tablePagination.total : to;
      tablePagination.current_page = +tablePagination.current_page;

      let linksCount = +tablePagination.total / +tablePagination?.per_page;
      linksCount = Math?.ceil(linksCount);
      tablePagination.last_page = linksCount;

      const maxValue = 6;
      let links = [];

      if (tablePagination?.current_page <= 3 && linksCount >= maxValue) {
        for (let i = 1; i <= maxValue; i++) {
          links.push(
              {
                active: i === tablePagination?.current_page,
                label: i + '',
              }
          );
        }
      } else {
        links = [
          {
            active: true,
            label: tablePagination?.current_page + '',
          },
        ];

        if (tablePagination?.current_page - 1 > 0) {
          links.unshift({
            active: false,
            label: tablePagination?.current_page - 1 + '',
          });
        }

        if (tablePagination?.current_page - 2 > 0) {
          links.unshift({
            active: false,
            label: tablePagination?.current_page - 2 + '',
          });
        }

        if (tablePagination?.current_page + 1 <= tablePagination.last_page) {
          links.push({
            active: false,
            label: tablePagination?.current_page + 1 + '',
          });
        }

        if (tablePagination?.current_page + 2 <= tablePagination.last_page) {
          links.push({
            active: false,
            label: tablePagination?.current_page + 2 + '',
          });
        }
      }

      tablePagination.links = [...links];

      let leftArrowLink = {
        active: false,
        label: "&laquo; Previous",
      };

      let rightArrowLink = {
        active: false,
        label: "Next &raquo;",
      };

      tablePagination.links.unshift(leftArrowLink);
      tablePagination.links.push(rightArrowLink);

      this.tablePagination = {...tablePagination};
    },
    cleanUnusedFilters() {
      let localTableFilters = {...this.tableFilters};

      for (const key in localTableFilters) {
        if (localTableFilters[key].opened) {

          if (localTableFilters[key].value === "range_sap" && !this.filtersInitObject['filter[range_sap][from]']) {
            localTableFilters[key].opened = false;
          }

          if (localTableFilters[key].value === "range_impression" && !this.filtersInitObject['filter[range_impression][from]']) {
            localTableFilters[key].opened = false;
          }

          if (localTableFilters[key].value === "range_keywords_count" && !this.filtersInitObject['filter[range_keywords_count][from]']) {
            localTableFilters[key].opened = false;
          }

          if (localTableFilters[key].value === "range_character_count" && !this.filtersInitObject['filter[range_character_count][from]']) {
            localTableFilters[key].opened = false;
          }

        }
      }

      this.tableFilters = {...localTableFilters};
    },
    async filterChecked(item) {
      let filterItemKey;
      const currentFilters = {...this.tableFilters};
      for (const key in currentFilters) {
        if (currentFilters[key]?.text === item.text) {
          filterItemKey = key;
          break;
        }
      }

      currentFilters[filterItemKey].isActive = !currentFilters[filterItemKey].isActive;

      if (currentFilters[filterItemKey].isActive === false) {
        const value = item.value !== 'keyword_tracked' ? null : {title: 'All'};
        await this.filterApplied(value, item.value);
      }

      this.tableFilters = {...currentFilters};
      this.closeFiltersTrigger++;
      let localTableFilters = {...this.tableFilters};

      for (const key in localTableFilters) {
        if (localTableFilters[key].value === item.value) {
          localTableFilters[key].opened = true;
        }
      }

      // this.cleanUnusedFilters();
      this.tableFilters = {...localTableFilters};
    },
    async saveTableWords(wordsToUse) {
      this.$emit('save-table-words', wordsToUse);
      this.setDefaultTableData();
    },
    resetTablePagination() {
      this.tablePagination.size = 50;
      this.tablePagination.per_page = 50;
      this.tablePagination.skip = 0;
      this.tablePagination.current_page = 1;
    },
  },
  async mounted() {
    this.setDefaultTableData();
    await this.fetchCountryCatalog();
    await this.fetchWordsTableData();
    this.filtersLoaded = true;
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'userData',
      'sidebarIsCollapsed',
      'currentCountry',
      'currentCountryManual',
      'isTablet',
      'isMobile',
      'countryList'
    ]),
    ...mapGetters('keywordsMetaData', [
      'sortingMeta',
      'urlQueryString',
    ]),
  },
  watch: {
    translateIsActive(newVal, oldVal) {
      this.$refs.wordsTable.translateIsActive = newVal;
    },
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.setDefaultTableData();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.setDefaultTableData();
      }
    },
    currentFilterReset(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setDefaultTableData();
      }
    }
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
<style scoped>
.filters-trigger {
  padding-right: 0;
}
.translate-block {
  margin-right: 10px;
}
</style>