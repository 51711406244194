export const wordsTableConfig = {
    headers: [
        {
            text: 'Words',
            sortable: true,
            value: 'word',
            sortName: 'keyword',
            sortBy: 'keyword',
            align: 'left',
            width: 'calc(100% - 772px - 200px)',
            // width: '20%',
            tooltipWidth: '248px',
            sortableIconAlign: 'right',
            isActive: true,
            headingClass: 'keywords-col',
            hasCheckbox: true,
        },
        {
            text: 'Locale',
            value: 'tools-locale',
            sortName: 'tools-locale',
            align: 'left',
            width: '200px',
            sortableIconAlign: 'left',
            tooltipWidth: '248px',
            isActive: true,
        },
        {
            text: 'Total <br />Popularity',
            value: 'total_popularity',
            sortName: 'popularity',
            sortBy: 'popularity',
            width: '160px',
            align: 'right',
            sortable: true,
            sortableIconAlign: 'left',
            tooltipWidth: '248px',
            isActive: true,
        },
        {
            text: 'Total Est. Daily <br />' +
                'Impressions',
            value: 'total_daily_impression',
            sortName: 'daily_impression',
            sortBy: 'daily_impression',
            width: '160px',
            align: 'right',
            sortable: true,
            sortableIconAlign: 'left',
            tooltipWidth: '248px',
            isActive: true,
        },
        {
            text: 'Total <br />' +
                'Keywords',
            value: 'total_keywords',
            sortName: 'keywords_count',
            sortBy: 'keywords_count',
            width: '160px',
            align: 'right',
            sortable: true,
            sortableIconAlign: 'left',
            tooltipWidth: '248px',
            isActive: true,
            // tooltipText: 'The number of apps found for this keyword.'
        },
        {
            text: 'Сharacter <br /> Сount',
            sortable: true,
            value: 'character_count',
            sortName: 'character_count',
            sortBy: 'character_count',
            align: 'right',
            width: '160px',
            sortableIconAlign: 'left',
            tooltipWidth: '248px',
            isActive: true,
            // tooltipText: 'Popularity ranges from 5 to 100.<br> 5 - lowest popularity. 100 - highest.'
        },
    ],
}