import Tooltips from "../tooltips";

export const wordsTableConfig = {
    headers: [
        {
            text: 'Keywords',
            sortable: true,
            value: 'keyword',
            sortName: 'keyword',
            sortBy: 'keyword',
            align: 'left',
            // width: '20%',
            width: 'calc(100% - 840px)',
            tooltipWidth: '248px',
            tooltipDirection: 'bottom',
            sortableIconAlign: 'right',
            isActive: true,
            headingClass: 'keywords-col',
            hasExtendedHeader: true,
            hasCheckbox: true,
            sortArrowsAlign: 'right',
        },
        {
            text: 'Popularity',
            sortable: true,
            value: 'popularity',
            sortName: 'popularity',
            sortBy: 'popularity',
            align: 'right',
            width: '140px',
            sortableIconAlign: 'left',
            tooltipWidth: '248px',
            tooltipDirection: 'bottom',
            isActive: true,
            tooltipText: Tooltips.SAP_AS_TOOLTIP
        },
        {
            text: 'Est. Daily <br />Impr.',
            value: 'daily_impression',
            sortName: 'daily_impression',
            sortBy: 'daily_impression',
            width: '140px',
            align: 'right',
            sortable: true,
            sortableIconAlign: 'left',
            isActive: true,
            tooltipWidth: '248px',
            tooltipDirection: 'bottom',
            tooltipText: 'Estimated Daily Impressions shows the number of impressions<br> ' +
                'a keyword receives each day within a given country.'
        },
        {
            text: 'App Rank',
            value: 'latest_rank',
            sortName: 'latest_rank',
            sortBy: 'latest_rank',
            width: '140px',
            align: 'right',
            sortable: true,
            sortableIconAlign: 'left',
            isActive: true,
            tooltipWidth: '248px',
            tooltipDirection: 'bottom',
            tooltipText: 'The current position of your app for the given keyword.'
        },
        {
            text: 'Results',
            value: 'results',
            sortName: 'applications_count',
            sortBy: 'applications_count',
            width: '140px',
            align: 'right',
            sortable: true,
            sortableIconAlign: 'left',
            isActive: true,
            tooltipWidth: '248px',
            tooltipDirection: 'bottom',
            tooltipText: 'The number of apps found for this keyword.'
        },
    ],
}