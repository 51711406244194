<template>
  <div class="metadata-table-layout">
    <table class="metadata-custom-table"
           :class="[{edit: tableMode === 'edit', view: tableMode === 'view'}, classes]">

      <tbody class="tbody">
      <template v-for="(row, key) in localTableData?.metas">
        <tr class="trow locale-row">
          <td class="td-item locale-name" colspan="3">{{ row?.locale_name }}</td>
        </tr>
        <tr class="trow middle-row">
          <td class="medium">
            <editable-table-cell :table-mode="tableMode"
                                 :initial-data="row?.title?.text"
                                 :store-data="storeData?.metas[key]?.title?.text"
                                 :duplicated-words="localTableData?.duplicated_words"
                                 :text-max-length="30"
                                 view-mode-placeholder="No title"
                                 edit-mode-placeholder="Enter the title"
                                 @change-table-mode="changeTableMode"
                                 @editable-data-changed="editableDataChanged(key, 'title', $event)">
              <template v-slot:cell-title>Title</template>
            </editable-table-cell>
          </td>
          <td class="no-border medium">
            <editable-table-cell :table-mode="tableMode"
                                 :initial-data="row?.sub_title?.text"
                                 :store-data="storeData?.metas[key]?.sub_title?.text"
                                 :duplicated-words="localTableData?.duplicated_words"
                                 :text-max-length="30"
                                 view-mode-placeholder="No subtitle"
                                 edit-mode-placeholder="Enter the subtitle"
                                 @change-table-mode="changeTableMode"
                                 @editable-data-changed="editableDataChanged(key, 'sub_title', $event)">
              <template v-slot:cell-title>Subtitle</template>
            </editable-table-cell>
          </td>
          <td rowspan="2" class="border-left large align-top words-block">

            <div class="cell-actions-block"
                 v-if="tableMode === 'view' && !allWordsToUseIsAdded(row)">
              <div class="action-item"
                   @click="clearUnusedWords(key, row?.words_to_use, row?.used_words)">Clear
                <div class="tooltip-text">Clear from unused words</div>
              </div>
              <div class="action-item blue"
                   @click="addAllWords(key, row?.words_to_use, row?.used_words)">Add All
                <div class="tooltip-text">Add to keywords</div>
              </div>
            </div>

            <editable-table-cell :table-mode="tableMode"
                                 classes="words-to-use-editable"
                                 :isCustomEditModeCell="true"
                                 :cell-type="'keyword-cell'"
                                 :show-length-limits="false"
                                 :text-max-length="10000"
                                 view-mode-placeholder="Your 'Words to Use' section is currently empty. To add words, please select from the table below the words you want to use in this locale."
                                 edit-mode-placeholder="Your 'Words to Use' section is currently empty. To add words, please select from the table below the words you want to use in this locale."
                                 :initial-data="null"
                                 :initial-list-data="wordsToUseProcessed(row?.words_to_use, row?.used_words)"
                                 :duplicated-words="row?.used_words"
                                 :no-highlight="true"
                                 @change-table-mode="changeTableMode"
                                 @editable-data-changed="editableDataChanged(key, 'words_to_use', $event)">
              <template v-slot:cell-title>Words to use</template>
            </editable-table-cell>

            <words-to-use v-if="row?.used_words?.length > 0"
                          :words-to-use="row?.words_to_use"
                          :used-words-data="row?.used_words"
                          mode="used"
            >Used words
            </words-to-use>

          </td>
        </tr>
        <tr class="trow large-row">
          <td colspan="2" class="no-border medium">
            <editable-table-cell :table-mode="tableMode"
                                 :cell-type="'keyword-cell'"
                                 :text-max-length="100"
                                 view-mode-placeholder="The system doesn't allow us to get Keywords from AppStore. Please add keywords manually to avoid duplicates."
                                 edit-mode-placeholder="Enter keywords"
                                 :initial-data="null"
                                 :initial-list-data="row?.keywords?.list"
                                 :duplicated-words="localTableData?.duplicated_words"
                                 @change-table-mode="changeTableMode"
                                 @editable-data-changed="editableDataChanged(key, 'keywords', $event)">
              <template v-slot:cell-title>Keywords</template>

              <template v-if="showBluredBlock"
                        v-slot:outer-placeholder>
                <span class="text-placeholder"
                      @click="bluredBlockClicked">
                  The system doesn't allow us to get Keywords from AppStore. Please add keywords manually to avoid
                duplicates.
                </span>
              </template>
            </editable-table-cell>
          </td>
        </tr>
      </template>
      </tbody>
    </table>

    <div class="blured-placeholder"
         @click="bluredBlockClicked"
         :class="{wide: Object?.keys(localTableData?.metas)?.length === 1}"
         v-if="showBluredBlock">
      <div class="text">
        The system doesn't allow us to get Keywords from AppStore. Please add keywords manually to avoid
        duplicates.
      </div>
    </div>
  </div>
</template>

<script>
import EditableTableCell from "@/views/MetaEditor/components/EditableTableCell/index.vue";
import AddKeywordsBlock from "@/views/MetaEditor/components/AddKeywordsBlock/index.vue";
import WordsToUse from "@/views/MetaEditor/components/WordsToUse/index.vue";

export default {
  name: "MetaEditorTable",
  components: {
    EditableTableCell,
    AddKeywordsBlock,
    WordsToUse,
  },
  emits: ['editable-table-value-changed', 'blured-block-clicked', 'change-table-mode', 'clear-unused-words', 'add-all-words'],
  props: {
    tableMode: {
      type: String,
      default: 'view'
    },
    tableData: {
      type: Object,
    },
    storeData: {
      type: Object,
    },
    classes: {
      type: String,
    },
    wordsToUse: {
      type: Object,
    },
    usedWordsData: {
      type: Object,
    },
  },
  data() {
    return {
      searchInput: '',
      localTableData: {},
    }
  },
  methods: {
    editableDataChanged(localeCode, itemType, newValue) {
      this.$emit('editable-table-value-changed', {
        localeCode,
        itemType,
        newValue
      });
    },
    bluredBlockClicked() {
      this.$emit('blured-block-clicked');
    },
    changeTableMode() {
      this.$emit('change-table-mode');
    },
    keywordClicked(localeCode, itemType, newValue, initialData) {
      let initialArray = [...initialData];
      initialArray.push(newValue);

      this.$emit('editable-table-value-changed', {
        localeCode,
        itemType,
        newValue: initialArray,
        mode: 'no-reload'
      });
    },
    clearUnusedWords(localeCode, wordsToUse, usedWordsData) {
      const unique = new Set(usedWordsData);
      this.$emit('clear-unused-words', {
        localeCode,
        wordsArray: [...unique],
      });
    },
    addAllWords(localeCode, wordsToUse, usedWordsData) {
      this.$emit('add-all-words', {
        localeCode,
        wordsArray: this.wordsToUseProcessed(wordsToUse, usedWordsData)
      });
    },
    wordsToUseProcessed(wordsToUse, usedWordsData) {
      let usedWordsDataLowerMap = {};
      usedWordsData?.forEach(item => {
        usedWordsDataLowerMap[item.toLowerCase()] = item;
      });

      return [...new Set(wordsToUse?.filter(item => !usedWordsDataLowerMap?.[item.toLowerCase()]))];
    },
    allWordsToUseIsAdded(data) {
      const wordsToUse = [...data.words_to_use].map(String)
      const includedWords = [...data.keywords.used_words, ...data.title.used_words, ...data.sub_title.used_words].map(String)

      for (let i = 0; i < wordsToUse.length; i++) {
          if (!includedWords.includes(wordsToUse[i])) {
            return false;
          }
      }

      return true;
    }
  },
  mounted() {
    this.localTableData = {...this.tableData};
  },
  computed: {
    showBluredBlock() {
      return false;
    },
  },
  watch: {
    tableData(newVal, oldVal) {
      this.localTableData = {...newVal};
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>