<template>
  <data-table :headers="headers"
              v-click-outside="outside"
              :items="currentTableData"
              :pagination="tablePagination"
              :sorting-meta="sortingMeta"
              :items-per-page="+tablePagination.per_page"
              :rows-per-page-items="[50, 100, 250, 500, 1000]"
              :fixed-width="true"
              :show-actions="false"
              :sorting-keys="{direction: 'order', sorting: 'sort_by', paginate: 'per_page'}"
              :no-pagination="false"
              :show-per-page-selector="true"
              :table-classes="'words-custom-table'"
              @check-all-clicked="checkAllClicked"
              :check-all-changed="checkAllChanged"
              :items-checked="keywordsTrackingChecked?.length"
              :items-amount="currentTableData?.length"
              :scroll-after-click="false"
              :full-row-options="true"
              ref="wordsTable"
              @update-sort="updateSort">

    <template v-slot:multi-delete>
      <div class="table-head-options-block">
        <span class="selected-count"
              v-if="keywordsTrackingChecked?.length > 0">{{
          keywordsTrackingChecked?.length
          }}/{{ currentTableData?.length }}</span>

        <div class="content-block with-tooltip copy-text">
          <custom-dropdown-absolute classes="w100p"
                                    :close-dropdown="closeLocaleDropdown"
                                    @dropdown-opened="dropdownOpened($event)">
            <template v-slot:header>
              <div class="current-locale"
                   v-if="currentBulkLocale?.localeName">
                {{ currentBulkLocale?.localeName }}
              </div>
              <div class="no-current-locale"
                   v-else>Select Locale
              </div>
            </template>
            <template v-slot:content>
              <div class="content-items-list">
                <div class="dropdown-item"
                     v-for="locale in countriesList">
                  <div class="locale-name"
                       @click="changeCurrentBulkLocale(locale)">
                    {{ locale?.localeName }}
                  </div>
                </div>
              </div>
            </template>
          </custom-dropdown-absolute>
        </div>

        <base-button height="36px"
                     width="92px"
                     font-size="16px"
                     border-radius="4px"
                     classes="save-locales-btn"
                     :disabled="!currentBulkLocale?.localeName"
                     @click="localeItemClicked(keywordsTrackingChecked, currentBulkLocale, 'bulk')">Save
        </base-button>
      </div>
    </template>

    <template v-slot:items="{item}">
      <td v-for="headerItem in headers"
          :style="{width: headerItem.width}"
          :class="[headerItem.align, {'large-padding': headerItem?.largePadding}, headerItem?.headingClass]">

        <div class="inner-content with-clickable-block"
             :class="{active: clickedWord && clickedWord === item.word && headerItem?.value === 'word'}"
             v-if="headerItem?.value !== 'tools-locale'">
          <template v-for="(tdItem, tdIndex) in item"
                    :key="tdIndex">
            <template v-if="tdIndex === headerItem.value">
              <template v-if="headerItem?.value === 'word'">
                <data-table-checkbox v-if="headerItem.hasCheckbox"
                                     @input-checked="keywordsTableInputChecked($event, item)"
                                     :checked="getKeywordCheckedValue(item.word)"
                />
                <div class="word-container cursor-pointer"
                     @click="wordClicked(item.word, $event)">

                  <span class="word">
                    <div class="word-content">
                      <span>{{ tdItem }}</span>
                      <span v-show="item.translate" class="translated-block" v-html="item.translate"></span>
                    </div>
                    <svg-icon icon="double-chevron-icon"
                              class="chevron"/>
                  </span>

                  <div class="icons-block">
                    <div class="mr-12 tool-item visible-on-hover">
                      <translate-one-tool :set-result-to="item" :to-translate-string="tdItem"></translate-one-tool>
                    </div>

                    <custom-tooltip direction-horizontal="left"
                                    direction-vertical="bottom"
                                    :straight="true"
                                    width="300px"
                                    max-height="505px"
                                    :with-padding="true"
                                    :delayed-closing="true"
                                    :style="{padding: 0}"
                                    :classes="'tool-item hover-bg-green'">
                      <template v-slot:slotTrigger>

                        <title-description-chart
                              :classes="'small-pie'"
                              :store="'google-play'"
                              width="18px"
                              height="18px"
                              :donut-data="{
                              title_appearance: inAsoLevel(item.inAso, 'title'),
                              short_description_appearance: inAsoLevel(item.inAso, 'sub_title'),
                              description_appearance: inAsoLevel(item.inAso, 'keywords')}"/>
                      </template>
                      <template v-slot:slotContent>

                        <div class="tooltip-chart-block">
                          <title-description-chart width="80px"
                                                   height="80px"
                                                   :store="'google-play'"
                                                   :show-numbers="true"
                                                   :donut-data="{title_appearance: inAsoLevel(item.inAso, 'title'),
                              short_description_appearance: inAsoLevel(item.inAso, 'sub_title'),
                              description_appearance: inAsoLevel(item.inAso, 'keywords')}"
                          />

                          <div class="text-list">
                            <ul>
                              <li :class="[inAsoLevel(item.inAso, 'title')]">
                                <span><span class="number">1.</span> Title</span>
                              </li>

                              <li :class="[inAsoLevel(item.inAso, 'sub_title')]">
                                <span><span class="number">2.</span> Subtitle</span>
                              </li>

                              <li :class="[inAsoLevel(item.inAso, 'keywords')]">
                                <span><span class="number">3.</span> Keywords</span>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="tooltip-in-aso-detailed"
                             v-if="item?.inAso">
                          <div class="tooltip-in-aso-detailed-inner">
                            <div class="in-aso-detail-item"
                                 v-for="tkdItem in item.inAso">

                              <div class="locale-name">{{ tkdItem.locale }}</div>
                              <div class="inclusion-block">
                                <div class="inclusion-item"
                                     :class="tkdItem.title.level">
                                  <div class="item-details"
                                       v-if="tkdItem.title?.missing">
                                    Title (Missing: <span class="red-text">
                                    {{ tkdItem.title?.missing }}
                                    </span>)
                                  </div>
                                  <span class="item-details"
                                        v-else>Title
                                    </span>
                                </div>
                                <div class="inclusion-item"
                                     :class="tkdItem.sub_title.level">
                                  <div class="item-details"
                                       v-if="tkdItem.sub_title?.missing">
                                    Subtitle (Missing: <span class="red-text">
                                   {{ tkdItem.sub_title?.missing }}
                                    </span>)
                                  </div>
                                  <span class="item-details"
                                        v-else>Subtitle
                                    </span>
                                </div>

                                <div class="inclusion-item"
                                     :class="tkdItem.keywords.level">
                                  <div class="item-details"
                                       v-if="tkdItem.keywords?.missing">
                                    Keywords (Missing: <span class="red-text">
                                   {{ tkdItem.keywords?.missing }}
                                    </span>)
                                  </div>
                                  <span class="item-details"
                                        v-else>Keywords
                                    </span>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                      </template>
                    </custom-tooltip>
                  </div>
                </div>
              </template>

              <template v-else-if="headerItem?.value === 'character_count'">
                {{ tdItem }}
              </template>

              <template v-else-if="headerItem?.value === 'total_popularity'">
                {{ tdItem }}
                <custom-tooltip v-if="false" :classes="'tool-item chart-icon-block'"
                                :style="{minWidth: '100px'}"
                                @click="popularityChartClicked(item)">
                  <template v-slot:slotTrigger>
                    <svg-icon icon="chart-icon"
                              class="icons-margin chart-icon"/>
                  </template>
                  <template v-slot:slotContent>
                    Open Chart
                  </template>
                </custom-tooltip>
              </template>

              <template v-else-if="headerItem?.value === 'total_daily_impression'">
                {{ tdItem }}
              </template>

              <template v-else-if="headerItem?.value === 'total_keywords'">
                {{ tdItem }}
              </template>
            </template>
          </template>
        </div>

        <div v-if="headerItem?.value === 'tools-locale'" class="locale-col inner-content no-padding">
          <custom-dropdown-absolute classes="w100p words-tab-absolute-dropdown"
                                    height="100%"
                                    :use-teleport="false"
                                    :close-dropdown="closeLocaleDropdown"
                                    @dropdown-opened="dropdownOpened">
            <template v-slot:header>
              <div class="current-locale"
                   v-if="item?.used_in?.length > 0">
                {{ item?.used_in }}
              </div>
              <div class="no-current-locale"
                   v-else>Use in locale
              </div>
            </template>
            <template v-slot:content>
              <div class="content-items-list">
                <div class="dropdown-item"
                     v-for="locale in countriesList">
                  <div class="locale-name"
                       @click="localeItemClicked(item, locale)">
                    {{ locale?.localeName }}
                  </div>
                </div>
              </div>
            </template>
          </custom-dropdown-absolute>
        </div>

      </td>
    </template>

  </data-table>

  <custom-modal :opened="showTableChartModal"
                :max-width="728"
                class-name="popularity-chart-modal"
                @closeModal="closeModal('showTableChartModal')">
    <sap-chart-modal :current-country-code="currentCountryCode"
                     :current-country-name="currentCountryName"
                     :current-keyword-item="currentKeywordItem"/>
  </custom-modal>
</template>

<script>
import DataTable from "@/components/DataTables/DataTable/index.vue";
import TitleDescriptionDonut from "@/components/UI/TitleDescriptionDonut/index.vue";
import SapChartModal from "@/components/Modals/SapChartModal/index.vue";
import TableCheckbox from "@/components/UI/TableCheckbox/index.vue";
import CustomDropdownAbsolute from "@/components/UI/CustomDropdownAbsolute/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import TranslateOneTool from "../../../DataTables/Tools/TranslateOneTool/index.vue";

export default {
  name: "WordsTable",
  emits: ['update-sort', 'save-table-words'],
  props: {
    headers: {
      type: Object
    },
    tableData: {
      type: Object,
    },
    sortingMeta: {
      type: Object,
    },
    tablePagination: {
      type: Object,
    },
    currentCountryCode: {
      type: String
    },
    currentCountryName: {
      type: String
    },
    countriesList: {
      type: Array,
    },
  },
  components: {
    TranslateOneTool,
    SvgIcon,
    CustomDropdownAbsolute,
    DataTable,
    'title-description-chart': TitleDescriptionDonut,
    'sap-chart-modal': SapChartModal,
    'data-table-checkbox': TableCheckbox,
  },
  data() {
    return {
      translateIsActive: false,
      showTableChartModal: false,
      currentKeywordItem: null,
      wordsToUse: [],
      wordsTableRadioModel: '',
      wordsTableRadioValue: 1,
      currentTableData: [],
      keywordsCheckedArray: [],
      closeLocalesList: 0,
      closeLocaleDropdown: 0,
      checkAllChanged: false,
      currentBulkLocale: {
        localeCode: null,
        localeName: null,
      },
      clickedWord: null,
    }
  },
  mounted() {
    this.currentTableData = [...this.tableData];
    this.keywordsCheckedArray = this.currentTableData.map(item => {
      return {
        checked: false,
        word: item?.word,
      }
    });
  },
  methods: {
    async popularityChartClicked(item) {
      this.setCurrentKeywordItem(item?.word, 'showTableChartModal');
    },
    updateSort(data) {
      this.$emit('update-sort', data);
    },
    detectTextOverflow(string) {
      if (!string?.length) {
        return;
      }

      let maxWidth;

      if (window.innerWidth > 991) {
        maxWidth = window.innerWidth - 960;
      } else if (window.innerWidth < 992) {
        maxWidth = 350;
      }

      const defaultCharWidth = 10;
      const stringLength = defaultCharWidth * string?.length;

      return stringLength > maxWidth;
    },
    inAsoLevel(array, type) {
      const fullResult = array.some(item => {
        return item?.[type]?.level === 'full';
      });

      const partlyResult = array.some(item => {
        return item?.[type]?.level === 'partial';
      });

      if (fullResult) {
        return 'green';
      } else if (!fullResult && partlyResult) {
        return 'yellow';
      } else {
        return 'grey';
      }
    },
    setCurrentKeywordItem(item, modalName) {
      this.currentKeywordItem = item;
      this.openModal(modalName);
    },
    localeItemClicked(item, locale, type) {
      let wordsToUse = [];
      const tempTableData = [...this.currentTableData];

      if (type !== 'bulk') {
        wordsToUse.push(item?.word);
        const idx = tempTableData?.findIndex(tempItem => tempItem?.word === item?.word);
        tempTableData[idx] = {
          ...item,
          used_in: locale?.localeName,
          used_in_code: locale?.localeCode,
        };

      } else {
        for (const key in item) {
          wordsToUse.push(item[key]?.word);
          const idx = tempTableData?.findIndex(tempItem => tempItem?.word === item[key]?.word);

          tempTableData[idx] = {
            ...tempTableData[idx],
            used_in: locale?.localeName,
            used_in_code: locale?.localeCode,
          };
        }
      }

      this.wordsToUse = [...wordsToUse];
      this.currentTableData = [...tempTableData];

      this.checkAllClicked();
      this.changeCurrentBulkLocale(null);
      this.$emit('save-table-words', {
        wordsArray: this.wordsToUse,
        localeCode: locale?.localeCode
      });
    },
    dropdownOpened(ref) {
      this.closeLocaleDropdown++;
      if (ref.getBoundingClientRect().bottom > this.$refs.wordsTable.$refs.table.getBoundingClientRect().bottom) {
        ref.classList.add('inverted');
      }
    },
    keywordsTableInputChecked(checked, item) {
      this.showCopyActiveFlag = false;

      const checkedArray = this.keywordsCheckedArray.map(keyword => {
        return {
          checked: keyword.word === item.word ? checked : keyword.checked,
          word: keyword?.word,
        };
      });

      this.keywordsCheckedArray = [...checkedArray];
      if (!checked) {
        this.checkAllChanged = checked;
      }
    },
    getKeywordCheckedValue(word) {
      return this.keywordsCheckedArray.find(item => item.word === word)?.checked;
    },
    selectAllKeywords() {
      let checkedArray = this.currentTableData.map(item => {
        return {
          ...item,
          checked: true
        }
      });

      this.keywordsCheckedArray = [...checkedArray];
      this.checkAllChanged = true;
    },
    unSelectAllKeywords() {
      let checkedArray = this.currentTableData.map(item => {
        return {
          ...item,
          checked: false
        }
      });

      this.keywordsCheckedArray = [...checkedArray];
      this.checkAllChanged = false;
    },
    checkAllClicked(allChecked) {
      let checkedArray = this.currentTableData.map(item => {
        return {
          ...item,
          checked: allChecked
        }
      });

      this.keywordsCheckedArray = [...checkedArray];
      this.checkAllChanged = allChecked;
    },
    changeCurrentBulkLocale(locale) {
      this.currentBulkLocale = {
        localeCode: locale?.localeCode,
        localeName: locale?.localeName,
      };
    },
    wordClicked(word, event) {
      if (event.target.closest(".table-translate-tool")) {
        return;
      }

      this.clickedWord = word;
      this.$store.dispatch('metaData/CURRENT_WORD_SELECTED', word);
    },
    outside() {
      this.clickedWord = null;
    },
  },
  computed: {
    keywordsTrackingChecked() {
      return this.keywordsCheckedArray.filter(keyword => keyword.checked);
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
<style scoped>
.word-content {
  display: flex;
  flex-direction: column;
}

.words-custom-table tbody td {
  height: auto !important;
  border-right: 1px solid var(--neutral-500);
  border-bottom: 1px solid var(--neutral-500);
  vertical-align: baseline;
  position: relative;
}
.words-custom-table tbody td .inner-content {
  height: auto !important;
  min-height: 48px;
  border: none !important;
}
.words-custom-table .word-container .svg-icons.chevron {
  min-height: 20px;
  min-width: 20px;
  margin-right: 5px;
}
</style>
<style>
/*
#fix_tooltip_teleport
 */
.words-custom-table .locale-col {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.words-tab-absolute-dropdown {
  position: initial;
}

.words-tab-absolute-dropdown .icon {
  top: initial;
}

.words-tab-absolute-dropdown  .absolute-select-options-wrap.active {
  position: absolute;
  top: calc(100% + 1px) !important;
  left: 0 !important;
  width: 100% !important;
}
.words-tab-absolute-dropdown  .absolute-select-options-wrap.active.inverted {
  bottom: calc(100% + 1px) !important;
  top: initial !important;
}
</style>