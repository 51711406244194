<template>
  <div class="keywords-options-block"
       v-click-outside="outside">
    <svg-icon icon="plus-circle"
              @click="addNewClicked"
              class="add-icon"/>
    <div class="keywords-add-block"
         v-if="showAddKeywordsBlock">
      <div class="search-input-block">
        <div class="input-wrap">
          <form>
            <label>
              <input type="text"
                     class="search-input"
                     placeholder="Search..."
                     v-model="searchInput">
            </label>
          </form>

          <svg-icon icon="search-icon-new"/>
        </div>
      </div>
      <div class="keywords-list"
           v-if="filteredKeywords?.length > 0">

        <div class="list-item"
             v-for="item in filteredKeywords"
             @click="keywordClicked(item)">
          <div class="item-text">{{ item }}</div>
          <svg-icon icon="plus"
                    class="add-keyword"/>
        </div>
      </div>

      <div class="no-results"
           v-else>
        No results
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddKeywordsBlock",
  props: {
    wordsToUse: {
      type: Object
    },
    usedWordsData: {
      type: Object,
    },
  },
  emits: ['keyword-clicked'],
  data() {
    return {
      searchInput: '',
      showAddKeywordsBlock: false,
    }
  },
  methods: {
    addNewClicked() {
      // this.showAddKeywordsBlock = !this.showAddKeywordsBlock;
    },
    outside() {
      this.showAddKeywordsBlock = false;
    },
    keywordClicked(item) {
      this.$emit('keyword-clicked', item);
    },
  },
  computed: {
    filteredKeywords() {
      let usedWords;
      if (this.usedWordsData?.isArray) {
        usedWords = [...this.usedWordsData];
      } else {
        usedWords = Object.values(this.usedWordsData);
      }
      const uniqueWords = this.wordsToUse?.keywords?.filter(word => !usedWords.includes(word));
      return uniqueWords?.filter(item => {
        return item.includes(this.searchInput);
      });
    }
  }
}
</script>

<style lang="scss">
.keywords-options-block {
  .svg-icons.add-icon {
    cursor: pointer;
    color: #6bd790;
    font-size: 16px;
    margin-top: 16px;
    margin-left: 16px;
    position: relative;
  }
}

.keywords-add-block {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(160, 190, 221, 0.3);
  border-radius: 4px;
  position: absolute;
  z-index: 15;
  width: 204px;
  top: 12px;
  left: 40px;

  .search-input-block {
    padding: 12px;
    border-bottom: 1px solid var(--neutral-500);

    .svg-icons {
      position: absolute;
      top: 10px;
      right: 14px;
      color: #a3b0c5;
      margin-bottom: 0;
      font-size: 20px;
    }
  }

  .search-input {
    height: 40px;
    background: #FFFFFF;
    border: 1px solid var(--neutral-500);
    border-radius: 4px;
    padding: 0 16px;

    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-800);
  }

  .search-input::placeholder {
    color: #A3B0C5;
    font-size: 16px;
    line-height: 24px;
  }

  .keywords-list {
    max-height: 204px;
    overflow-y: auto;
    padding: 4px 0;

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      width: 100%;
      cursor: pointer;
      transition: all .3s;
      padding: 0 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-800);

      &:hover {
        background: var(--neutral-200);
      }

      .svg-icons {
        color: #a3b0c5;
      }
    }
  }

  .keywords-list::-webkit-scrollbar {
    width: 3px;
  }

  .keywords-list::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .keywords-list::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #eaeff8;
  }

  .no-results {
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-800);
  }
}
</style>