<template>
  <div class="table-editable-cell-block"
       :class="[{'edit-mode': tableMode === 'edit'}, classes]">
    <div class="cell-title-block"
         :class="{'edit-mode': tableMode === 'edit'}">
      <div class="title">
        <slot name="cell-title"></slot>
      </div>
      <div class="character-counter"
           v-if="showLengthLimits"
           :class="{green: inputModel?.length === textMaxLength, red: inputModel?.length === 0}">
        {{ sanitizeText(inputModel)?.length ?? 0 }}/{{ textMaxLength }}
      </div>
    </div>

    <div class="table-editable-cell"
         :class="{view: tableMode === 'view'}">

      <template v-if="tableMode === 'view'">
        <div class="view-mode-cell"
             :class="{keywords: cellType === 'keyword-cell'}">
          <div v-if="inputModel === '' || !inputModel"
               class="no-title-block">
            {{ viewModePlaceholder }}
          </div>
          <template v-else>
            <slot name="outer-placeholder">
              <span v-html="highlightduplicatedWords(inputModel)"></span>
            </slot>
          </template>

          <table-cell-tooltip v-if="showTooltip"
                              @close-icon-clicked="showTooltip = false"
                              @icon-clicked-outside="tooltipClickedOutside($event)">
            <template v-slot:tooltip-content>{{ storeData }}</template>
          </table-cell-tooltip>

        </div>
      </template>
      <template v-else>
        <textarea class="edit-mode-cell"
                :class="{ 'custom-edit-mode-cell': isCustomEditModeCell }"
                v-model="inputModel"
                @input="inputChanged"
                :maxlength="textMaxLength"
                :placeholder="editModePlaceholder ?? ''"></textarea>
      </template>
    </div>

    <div class="cell-options"
         v-if="tableMode === 'view'">
      <custom-tooltip :classes="'option-item edit-option'"
                      :style="{minWidth: '30px'}"
                      @click="changeTableMode">
        <template v-slot:slotTrigger>
          <svg-icon icon="pencil-icon"
                    class="icons-margin chart-icon"/>
        </template>
        <template v-slot:slotContent>
          Edit Meta
        </template>
      </custom-tooltip>

      <custom-tooltip :classes="'option-item clock-option'"
                      v-if="cellType !== 'keyword-cell'"
                      :style="{minWidth: '120px'}"
                      @click="showTooltip = !showTooltip">
        <template v-slot:slotTrigger>
          <svg-icon icon="clock-icon"></svg-icon>
        </template>
        <template v-slot:slotContent>
          App Store Meta
        </template>
      </custom-tooltip>

      <custom-tooltip :classes="'option-item copy-option'"
                      :style="{minWidth: '135px'}"
                      @click="copyText">
        <template v-slot:slotTrigger>
          <svg-icon icon="copy"></svg-icon>
        </template>
        <template v-slot:slotContent>
          Copy to clipboard
        </template>
      </custom-tooltip>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import TableCellTooltip from "@/views/MetaEditor/components/TableCellTooltip/index.vue";
import {splitTextToParts} from "../../../../utils/utils";

export default {
  name: "EditableTableCell",
  components: {
    SvgIcon,
    TableCellTooltip
  },
  emits: ['editable-data-changed', 'change-table-mode'],
  props: {
    tableMode: {
      type: String,
      default: 'view'
    },
    viewModePlaceholder: {
      type: String,
      default: 'No title'
    },
    editModePlaceholder: {
      type: String,
      default: 'Enter text'
    },
    storeData: {
      type: String,
    },
    initialData: {
      type: String,
    },
    initialListData: {
      type: Array,
    },
    duplicatedWords: {
      type: Array,
    },
    textMaxLength: {
      type: Number,
      default: 30,
    },
    cellType: {
      type: String,
      default: ''
    },
    showLengthLimits: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
    },
    noHighlight: {
      type: Boolean,
      default: false,
    },
    noInitialTrim: {
      type: Boolean,
      default: false,
    },
    isCustomEditModeCell: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputModel: '',
      inputArray: [],
      showTooltip: false,
    }
  },
  mounted() {
    if (this.initialData !== '') {
      this.inputModel = this.noInitialTrim ? this.initialData : this.initialData?.substring(0, this.textMaxLength);
    }

    if (this.initialData === null && this.initialListData?.length > 0) {
      this.inputArray = [...this.initialListData];
      const nonEmptyItem = this.initialListData.filter(item => item !== '');
      this.inputModel = nonEmptyItem.join(',');
    }
  },
  methods: {
    inputChanged(event) {
      if (this.initialData === null && this.initialListData?.length > 0) {
        const pattern = new RegExp(`[^\\p{L}\\p{N}]`, 'ugi');
        this.$emit('editable-data-changed', [...new Set(this.inputModel.split(pattern))]);
      } else {
        this.$emit('editable-data-changed', this.inputModel);
      }

    },
    sanitizeText(str) {
      return str?.replace(/(<([^>]+)>)/gi, "");
    },
    copyText() {
      const tempText = document.createElement("textarea");

      if (this.initialData !== '') {
        tempText.value = this.sanitizeText(this.initialData);
      }

      if (this.initialData === null && this.initialListData?.length > 0) {
        tempText.value = this.initialListData.join(',');
      }

      document.body.appendChild(tempText);
      tempText.select();

      document.execCommand("copy");
      document.body.removeChild(tempText);
    },
    highlightduplicatedWords(inputString) {
      if (!inputString) {
        return inputString;
      }

      if (this.duplicatedWords?.length > 0) {
        let duplicatedWordsIndex = [];
        this.duplicatedWords.forEach((word) => {duplicatedWordsIndex[(word + '').toLowerCase()] = word});

        let splitInput = splitTextToParts(inputString);

        let replacedInputArr = [];
        splitInput.forEach((inputWord) => {
          if (inputWord === ' ') {
            replacedInputArr.push("&nbsp;");
            return;
          }

          if (duplicatedWordsIndex[inputWord.toLowerCase()]) {
            if (!this.noHighlight) {
              replacedInputArr.push(`<span class="highlight">${inputWord}</span>`);
            }
          } else {
            replacedInputArr.push(inputWord);
          }
        });

        return replacedInputArr.join('');
      } else {
        return inputString;
      }
    },
    changeTableMode() {
      this.$emit('change-table-mode');
    },
    tooltipClickedOutside(e) {
    }
  },
  watch: {
    viewModePlaceholder(val) {
    },
    inputModel(val) {
    },
    tableMode(newVal, oldVal) {
      if (newVal === 'view') {
        if (this.initialData !== '') {
          this.inputModel = this.initialData;
        }

        if (this.initialData === null && this.initialListData?.length > 0) {
          this.inputArray = [...this.initialListData];
          this.inputModel = this.initialListData.join(',');
        }
      }
    },
    initialData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {

        // if (newVal !== '') {
          // this.inputModel = this.initialData?.substring(0, this.textMaxLength);
          this.inputModel = newVal?.substring(0, this.textMaxLength);
        // }

        if (newVal === null && this.initialListData?.length > 0) {
          this.inputArray = [...this.initialListData];
          const nonEmptyItem = this.initialListData.filter(item => item !== '');
          this.inputModel = nonEmptyItem.join(',');
        }
      }
    },
    initialListData(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        if (this.tableMode !== 'edit') {
          const nonEmptyItem = newVal?.filter(item => item !== '');
          this.inputModel = nonEmptyItem.join(',');
        }
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>